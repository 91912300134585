import { Box, Divider, Popover, Typography } from "@mui/material";
import { PopoverComponentProps } from "./types";
import { useNavigate } from "react-router-dom";
import { SignoutTrigger } from "../../../domian/app/AppSlice";
import { useSelector, useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";

export const PopoverComponent = (props: PopoverComponentProps): JSX.Element => {
  const navigate = useNavigate();
  const { anchorEl, onClose, open } = props;
  const { userInfo } = useSelector((state: any) => state.globalAppState);

  const dispatch = useDispatch();
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 225 } }}
    >
      <Box
        sx={{
          py: 1,
          px: 2,
        }}
      >
        <Typography variant="overline" sx={{ textTransform: "capitalize" }}>
          {`${userInfo?.jobRole?.name?.toLowerCase()} (${userInfo?.ownLocation?.name?.toLowerCase()})`}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          py: 1,
          px: 2,
          cursor: "pointer",
          "&:hover": {
            background: "rgb(240,240,240)",
          },
        }}
        onClick={() => {
          dispatch(SignoutTrigger());
          dispatch({ type: "SIGNOUT_REQUEST" });
          navigate("/");
        }}
      >
        <Typography variant="overline" sx={{ textTransform: "capitalize" }}>
          Log Out
          <LogoutIcon
            sx={{
              fontSize: "1.1rem",
              position: "relative",
              top: "4px",
              marginLeft: "5px",
            }}
          />
        </Typography>
      </Box>
    </Popover>
  );
};
