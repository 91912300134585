import { createSlice } from "@reduxjs/toolkit";
import {
  authLogin,
  refreshUserData,
  verifyResetPasswordLink,
} from "../../api/Api.service";
import { RootState } from "../../store/store";
import { AppState, UserDetails } from "../../types/data.types";

enum Roles {
  User = "1",
  Admin = "2",
}

interface PayloadUserInfo {
  userInfo: UserDetails;
  authToken: string;
}

const initialUserState: UserDetails = {
  fullName: "",
  Role: Roles.User,
  ID: "",
  allowedLocations: [],
  ownLocation: {
    name: "",
    locationId: "",
  },
  jobRole: {
    _id: "",
    name: "",
    key: "",
    description: "",
  },
};

const initialState: AppState = {
  userInfo: initialUserState,
  authToken: "",
  isLoading: false,
  isSnackBarOpen: false,
  snackBarVariant: "success",
  snackBarMessage: "",
  isAuthenticated: false,
  refreshToken: "",
  expiryTime: "",
  isMobileView: false,
  verifyResetLinkFailed: false,
  masterLocation: initialUserState.ownLocation,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    refreshStatePersist: (state, { payload }) => {
      state.authToken = payload?.token;
      state.userInfo = payload?.userinfo;
      state.isAuthenticated = payload?.token ? true : true;
    },
    triggrViewChange: (state, { payload }) => {
      state.isMobileView = payload;
    },
    toggleSnacker: (state, { payload }) => {
      const { isOpen, variant, message } = payload;
      state.isSnackBarOpen = isOpen;
      state.snackBarVariant = variant;
      state.snackBarMessage = message;
    },
    toggleLoader: (state, { payload }) => {
      const { isLoading } = payload;
      state.isLoading = isLoading;
    },
    updateMasterLocation: (state, { payload }) => {
      state.masterLocation = payload;
    },
    SignoutTrigger: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.fulfilled, (state, { payload }: any) => {
      state.userInfo = payload?.userInfo;
      state.authToken = payload?.authToken;
      state.refreshToken = payload?.refreshToken;
      state.expiryTime = payload?.expiryTime;
      state.isAuthenticated = payload?.authToken ? true : false;
      state.isLoading = false;
      state.masterLocation = payload?.userInfo?.ownLocation;
    });
    builder.addCase(refreshUserData.fulfilled, (state, { payload }: any) => {
      state.authToken = payload?.authToken;
      state.refreshToken = payload?.refreshToken;
      state.expiryTime = payload?.expiryTime;
    });
    builder.addCase(
      verifyResetPasswordLink.fulfilled,
      (state, { payload }: any) => {
        state.verifyResetLinkFailed = payload;
      }
    );
  },
});

export const appSliceReducer = appSlice.reducer;
export const {
  SignoutTrigger,
  toggleSnacker,
  toggleLoader,
  refreshStatePersist,
  triggrViewChange,
  updateMasterLocation,
} = appSlice.actions;
export const globalSelector = (state: RootState) => state.globalAppState;
