import { Button, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import LoginIcon from "@mui/icons-material/Login";
import "./style.css";
import { authLogin } from "../../api/Api.service";
import theme from "../../Themes";

var ImgWithDoc = require("../../assets/famdocwithdoc.png");
var ImgWithoutDoc = require("../../assets/austinLogo.png");

interface Values {
  username: string;
  password: string;
}

const SignIn = () => {
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );

  return (
    <>
      <div className="login-container">
        {!isMobileView && (
          <div className="left-login-container">
            <img src={ImgWithDoc} alt="" className="left-login-container-img" />
          </div>
        )}

        <div className="right-login-container">
          <img
            width={"200px"}
            src={ImgWithoutDoc}
            alt=""
            className="right-login-container-img"
          />
          <div className="login-form-container">
            <div style={{ padding: 30 }}>
              <Formik
                initialValues={{
                  emailId: "",
                  password: "",
                }}
                validate={(values) => {
                  let errors: Partial<Values> = {};
                  if (!values.emailId) {
                    errors.username = "Please Enter Email";
                  }
                  if (!values.password) {
                    errors.password = "Please Enter Password";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  dispatch(authLogin(values));
                }}
              >
                {({
                  values,
                  submitForm,
                  resetForm,
                  isSubmitting,
                  touched,
                  errors,
                }) => (
                  <>
                    <Form>
                      <Grid container gap={3}>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            name="emailId"
                            id="emailId"
                            label="Email ID"
                            variant="outlined"
                            required
                            size="small"
                          ></Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            name="password"
                            id="password"
                            label="Password"
                            variant="outlined"
                            size="small"
                            required
                            type={"password"}
                          ></Field>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="login-form-forgot">
                            <span
                              onClick={() => {
                                navigate("./forgotpassword");
                              }}
                              style={{
                                color: theme.palette.indicator.main,
                              }}
                            >
                              Forgot your password?
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={submitForm}
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                            endIcon={<LoginIcon />}
                            sx={{ fontSize: "16px" }}
                          >
                            Log in
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;

// const postData = {
//   username: "rahul.balodi@classicinformatics.com",
//   password: "admin123",
// };
