import {
  GET_MASTERSCHEDULE_REPORTS,
  GET_DROPDOWN_MASTERSCHEDULE_REPORTS,
} from "../../ActionConstants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../Api.utils";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../../Api.service";
import { toggleLoader, toggleSnacker } from "../../../domian/app/AppSlice";

export const getMasterScheduleReportsListing = createAsyncThunk(
  GET_MASTERSCHEDULE_REPORTS,
  async (payload: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    const { pageIndex, pageSize, location } = payload;
    try {
      const url = `masterSchedule?${pageIndex ? `page=${pageIndex}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${location !== "all" ? `locationId=${location}&` : ""}`;

      dispatch(toggleLoader(openLoaderPayload));
      const { data } = await API.get(url, { signal });

      const response = data?.data?.finalResponse;

      const temp = response[0];

      const Obj1: any = {
        id: temp.id,
        trayName: "Total Assets, Confirmation status",
        isClickEnable: true,
      };
      for (let key in temp) {
        Obj1[key] = temp[key];
      }

      const MasterScheduleData = [] as any;
      MasterScheduleData.push(Obj1);

      if (response.length > 1) {
        for (let i = 1; i < response.length; i++) {
          MasterScheduleData.push(response[i]);
        }
      }
      const finalObj = {
        finalResponse: MasterScheduleData,
        count: data?.data?.count,
      };

      return finalObj;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Fetching the Reports ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getmasterSchedukReportsFilterData = createAsyncThunk(
  GET_DROPDOWN_MASTERSCHEDULE_REPORTS,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steelShortage/dropdown`;
      const response = await API.get(url, { signal });
      return response?.data?.data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Steel Shortage Reports Filter Data ...!"
          )
        )
      );
      return {};
    } finally {
    }
  }
);
