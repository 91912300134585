import { TableCell, TableRow, Typography } from "@mui/material";

export default function TableRowNoResults({ colSpan }: any): JSX.Element {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ py: 3 }}>
        <Typography gutterBottom align={"center"} variant={"subtitle1"}>
          No Records Available
        </Typography>
      </TableCell>
    </TableRow>
  );
}
