import { createSlice } from "@reduxjs/toolkit";
import {
  getEditSeel,
  getFilterData,
  getSteelList,
} from "../../../api/steel/Api.service";

interface SteelState {
  steelListData: any;
  steelEditData: any;
  filterData: any;
}

const initialState: SteelState = {
  steelListData: {},
  steelEditData: {},
  filterData: {},
};
const SteelSlice = createSlice({
  name: "steel",
  initialState,
  reducers: {
    clearEditData: (state) => {
      state.steelEditData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSteelList.fulfilled, (state, { payload }) => {
      state.steelListData = payload;
    });
    builder.addCase(getEditSeel.fulfilled, (state, { payload }) => {
      state.steelEditData = payload;
    });
    builder.addCase(getFilterData.fulfilled, (state, { payload }) => {
      state.filterData = payload;
    });
  },
});

export const SteelSliceReducer = SteelSlice.reducer;
export const { clearEditData } = SteelSlice.actions;
