import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TocIcon from "@mui/icons-material/Toc";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import TableChartIcon from "@mui/icons-material/TableChart";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { dateFormater } from "./AddScheduleOrder";
import { checkPermission } from "../../utils/checkPermission";
import { truncateText } from "../../utils/truncateText";
import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import theme from "../../Themes";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);
export const LargeScreenTableSearch = (props: any) => {
  const { isMobileView, userInfo, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [value, setValue] = useState<string[] | []>([]);

  const { filterData } = useSelector((state: RootState) => state.scheduleState);
  const { setScheduleData, summaryDataFilters, setSummaryDataFilters } = props;

  const {
    componentName = "",
    isTableView,
    setIsTableView,
    refreshSchedule,
    setPermission,
  } = props;

  const updateScheduleData = (
    value: string | number | string[],
    name: any,
    event?: any
  ) => {
    if (name === "trayName1") {
      // @ts-ignore
      if (value.find((str: any) => str === "All")) value = [];
    }
    if (name === "deliveryStartDate1" || name === "deliveryEndDate1") {
      if (event) value = event.toISODate();
      else value = "";
    }

    if (name === "location") {
      setPermission(() => {
        const getPermission = checkPermission(
          value as string,
          userInfo?.ownLocation?.locationId as string,
          userInfo?.jobRole?.key as string,
          "Schedule Summary"
        );
        return getPermission;
      });
    }

    setSummaryDataFilters({
      ...summaryDataFilters,
      [name]: value !== "All" ? value : "",
    });
  };

  const renderTags = (value: any, getTagProps: any) => {
    if (value.length === 0) return null;

    const totalSelectedCount = value.length - 1;
    const firstOption = value[0];

    return (
      <>
        <Chip
          label={truncateText(firstOption, 7)}
          {...getTagProps({ index: 0 })}
          size="small"
        />
        {totalSelectedCount > 0 ? <span>{`+${totalSelectedCount}`}</span> : ""}
      </>
    );
  };

  const clearAllFilters = () => {
    setValue([]);
    setSummaryDataFilters({
      trayName1: [],
      probability1: "75 - 100",
      customerName1: "",
      deliveryStartDate1: "",
      deliveryEndDate1: "",
      location1: masterLocation?.locationId,
    });
  };
  if (filterData?.location?.length) {
    return (
      <Box display={"flex"} alignItems={"center"} data-sd={1}>
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <Autocomplete
              multiple
              id="checkboxes-tags-trayName"
              size="small"
              options={filterData?.trayName}
              disableCloseOnSelect
              getOptionLabel={(option: string) => option}
              value={value}
              limitTags={1}
              onChange={(event: any, newValue: string[]) => {
                setValue(newValue);
                updateScheduleData(newValue, `trayName1`);
              }}
              renderTags={renderTags}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ width: "100px !important" }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginLeft: "-10px" }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Asset Name"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      width: "226px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="probability1"
              id="probability1"
              label={`Probability %`}
              select
              variant="outlined"
              fullWidth
              value={summaryDataFilters?.probability1}
              onChange={(e) =>
                updateScheduleData(e.target.value, e.target.name)
              }
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.trayName?.length ? (
                filterData?.probability?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="customerName1"
              id="customerName1"
              label={`Customer Name`}
              select
              variant="outlined"
              fullWidth
              value={summaryDataFilters?.customerName1}
              onChange={(e) =>
                updateScheduleData(e.target.value, e.target.name)
              }
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.customerName?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
              {filterData?.trayName?.length ? (
                filterData?.customerName?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
            </TextField>
          </Box>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Start Date"
              format="dd/MM/yyyy"
              onChange={(e) => updateScheduleData("", "deliveryStartDate1", e)}
              value={
                summaryDataFilters?.deliveryStartDate1
                  ? DateTime.fromJSDate(
                      new Date(summaryDataFilters?.deliveryStartDate1)
                    )
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "deliveryStartDate1",
                  name: "deliveryStartDate1",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              // format="DD-MM-YYYY"
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="End Date"
              format="dd/MM/yyyy"
              onChange={(e) => updateScheduleData("", "deliveryEndDate1", e)}
              value={
                summaryDataFilters?.deliveryEndDate1
                  ? DateTime.fromJSDate(
                      new Date(summaryDataFilters?.deliveryEndDate1)
                    )
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "deliveryEndDate1",
                  name: "deliveryEndDate1",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              // format="DD-MM-YYYY"
            />
          </LocalizationProvider>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="location1"
              id="location1"
              label={`Location`}
              select
              variant="outlined"
              fullWidth
              value={summaryDataFilters?.location1}
              onChange={(e) =>
                updateScheduleData(e.target.value, e.target.name)
              }
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.location?.length ? (
                <MenuItem value={"all"}>All</MenuItem>
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
              {filterData?.location?.length ? (
                filterData?.location?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option._id}>
                    {option.name[0] + option.name.toLowerCase().slice(1)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
        {componentName === "ScheduleSummary" && (
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "end",
              marginBottom: "15px",
            }}
          >
            <ButtonGroup aria-label="outlined primary button group">
              <Tooltip title="Tabular View">
                <Button
                  variant={isTableView ? "contained" : "outlined"}
                  size="small"
                  sx={{
                    width: "54px",
                    height: "35px",
                  }}
                  onClick={() => {
                    refreshSchedule();
                    setScheduleData({
                      location: masterLocation?.locationId,
                      probability: "75 - 100",
                      trayName: [],
                      customerName: "",
                      deliveryStartDate: "",
                      deliveryEndDate: "",
                      processingStartDate: "",
                      processingEndDate: "",
                    });
                    setSummaryDataFilters({
                      location1: masterLocation?.locationId,
                      trayName1: [],
                      probability1: "75 - 100",
                      customerName1: "",
                      deliveryStartDate1: "",
                      deliveryEndDate1: "",
                    });
                    setIsTableView(true);
                  }}
                >
                  <TableChartOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Summary View">
                <Button
                  variant={!isTableView ? "contained" : "outlined"}
                  size="small"
                  sx={{
                    width: "54px",
                    height: "35px",
                  }}
                  onClick={() => {
                    refreshSchedule();
                    setScheduleData({
                      location: masterLocation?.locationId,
                      probability: "75 - 100",
                      trayName: [],
                      customerName: "",
                      deliveryStartDate: "",
                      deliveryEndDate: "",
                      processingStartDate: "",
                      processingEndDate: "",
                    });
                    setSummaryDataFilters({
                      location1: masterLocation?.locationId,
                      trayName1: [],
                      probability1: "75 - 100",
                      customerName1: "",
                      deliveryStartDate1: "",
                      deliveryEndDate1: "",
                    });
                    setIsTableView(false);
                  }}
                >
                  <TocIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        )}
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const ScheduleFilters2 = (props: any) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
export const ScheduleWithoutFilters = (props: any) => {
  const { isTableView, setIsTableView } = props;
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      pr={2}
    >
      <Box></Box>
      <Box>
        <ButtonGroup aria-label="outlined primary button group">
          <Button
            variant={isTableView ? "contained" : "outlined"}
            onClick={() => setIsTableView(true)}
          >
            <Tooltip title="Tabular View">
              <TableChartIcon />
            </Tooltip>
          </Button>
          <Button
            variant={!isTableView ? "contained" : "outlined"}
            onClick={() => setIsTableView(false)}
          >
            <Tooltip title="Summary View">
              <SummarizeIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
