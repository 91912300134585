import { GridColDef } from "@mui/x-data-grid";

const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export const forwardMonthsColumns = (monthIndex: number): GridColDef[] => {
  const finalColums = [
    {
      field: "trayName",
      headerName: "ASSET NAME",
      width: 250,
      sortable: true,
    },
  ];

  const currentMonth = months[monthIndex];

  const index = months.indexOf(currentMonth?.toLowerCase());

  for (let i = 0; i < 12; i++) {
    const nextIndex = (index + i) % 12;
    const monthHeader = months[nextIndex];
    const newColums = {
      field: monthHeader,
      headerName: monthHeader?.toUpperCase(),
      width: 75,
      sortable: true,
      align: "center",
      headerAlign: "center",
      // @ts-ignore
      valueGetter: (params) => {
        if (!params.value) {
          return 0;
        }
        return params.value;
      },
    };
    finalColums.push(newColums);
  }

  return finalColums as GridColDef[];
};
