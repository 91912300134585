export const intialSteelData = {
  id: "",
  name: "",
  code: "",
  grade: "",
  brand: "",
  manufacturer: "",
  thickness: "",
  width: "",
  length: "",
  minQty: "",
};
