export const initailShortageData = {
  id: "",
  steelName: "",
  jan: "",
  feb: "",
  mar: "",
  apr: "",
  may: "",
  jun: "",
  jul: "",
  aug: "",
  sep: "",
  oct: "",
  nov: "",
  dec: "",
};
export const initialTempShortageData = {
  id: "",
  trayType: "",
  customerName: "",
  quantity: "",
  probability: "",
  purchaseDate: "",
  deliveryDate: "",
};
