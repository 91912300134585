import {
  Box,
  Button,
  Container,
  Tooltip,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { months } from "./data";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import {
  getMasterScheduleReportsListing,
  getmasterSchedukReportsFilterData,
} from "../../api/reports/masterScheduleReports/MasterScheduleReports.service";
import { finalUrl } from "../../api/Api.utils";

export const MasterScheduleScreen = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);

  const { filterData } = useSelector(
    (state: RootState) => state.masterScheduleReportsState
  );

  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const synopsisStyles = {
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "150px",
    whiteSpace: "nowrap",
  };

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const { masterScheduleReports, rowCount } = useSelector(
    (state: RootState) => state.masterScheduleReportsState
  );
  const [location, setLocation] = useState(masterLocation.locationId);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [currentMonth, setCurrentMonth] = useState("jan");

  const refreshValues = () => {
    const searchDate = {
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      location: location,
    };
    dispatch(getMasterScheduleReportsListing(searchDate));
    dispatch(getmasterSchedukReportsFilterData());
  };

  useEffect(() => {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth();
    const shortMonth = months[monthIndex];
    setCurrentMonth(shortMonth);
  }, []);

  useEffect(() => {
    setLocation(masterLocation.locationId);
  }, [masterLocation]);

  useEffect(() => {
    refreshValues();
  }, [pagination.pageIndex, pagination.pageSize, location]);
  const totalTrayTypeUi = (obj: any) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <div className="yellow">{obj?.value}</div>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          <Box>
            <span className="green" title="confirmed">
              {obj?.confirmed}
            </span>
          </Box>{" "}
          <Box>
            <span className="red" title="unconfirmed">
              {obj?.unconfirmed}
            </span>
          </Box>
        </Box>
      </Box>
    );
  };
  const renderClickablecell = (params: any) => {
    const row = params.row.original;
    const field = params.column.id;
    const isClickEnable = row?.isClickEnable;
    const hrefLink = `/schedule`;
    return (
      <>
        {isClickEnable ? (
          <span className="yellow">
            <b>
              {field === "trayName"
                ? params.cell.getValue().toLocaleString()
                : totalTrayTypeUi(params.cell.getValue())}
            </b>
          </span>
        ) : field === "trayName" ? (
          <span>
            <b
              // @ts-ignore
              style={synopsisStyles}
              title={params.cell.getValue().toLocaleString()}
            >
              {params.cell.getValue().toLocaleString()}
            </b>
          </span>
        ) : (
          renderColoredCell(params)
        )}
      </>
    );
  };
  const renderColoredCell = (params: any) => {
    const values =
      typeof params.cell?.getValue() === "string"
        ? params.cell?.getValue()?.split(" ")
        : params.cell?.getValue();

    const hightlight = params?.row?.original?.highlight;

    return (
      <>
        {values?.length > 1 ? (
          <Box
            color="primary"
            sx={{
              backgroundColor: `${hightlight && "pink"}`,
              borderLeft: `${hightlight && "2px solid"}`,
              borderRight: `${hightlight && "2px solid"}`,
              borderColor: `${hightlight && "tomato"}`,
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              gap: "10px",
            }}
          >
            <Typography
              sx={{ ...synopsisStyles, paddingX: "5px", maxWidth: "60px" }}
              title={values && values[0]}
            >
              {values && values[0]}
            </Typography>
            {/* <Typography></Typography> */}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              padding: "10px",
            }}
          >
            <Typography>{values === 0 ? "" : values}</Typography>
          </Box>
        )}
      </>
    );
  };

  const columnMonths: any[] = [];

  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  for (let i = 0; i < 18; i++) {
    const month = (currentMonthIndex + i) % 12;
    const year = currentYear + Math.floor((currentMonthIndex + i) / 12);
    const monthYear = `${getMonthName(month)}-${year}`;
    columnMonths.push(monthYear);
  }

  function getMonthName(month: number) {
    const monthNames = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
    return monthNames[month];
  }

  const columnDefiner: (currentMonth: string) => MRT_ColumnDef[] = () => {
    const finalColums = [
      {
        accessorKey: "trayName",
        size: 150,
        header: "ASSET NAME",
        sortable: false,
        Cell: (params: any) => renderClickablecell(params),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ];

    for (let i = 0; i < 18; i++) {
      const monthHeader = columnMonths[i];
      const newColums = {
        accessorKey: monthHeader,
        header: monthHeader.split("-")[0].toUpperCase(),
        size: 90,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: (params: any) => renderClickablecell(params),
        sortable: false,
      };
      finalColums.push(newColums);
    }

    return finalColums as MRT_ColumnDef[];
  };

  const columns: MRT_ColumnDef[] = columnDefiner(currentMonth);

  const onDownload = async () => {
    const downloadUrl =
      finalUrl +
      `masterSchedule/csvReport?page=${pagination.pageIndex + 1}&pageSize=${
        pagination.pageSize
      }`;

    window.location.href = downloadUrl;
  };

  const CustomToolbar = (props: any) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row-reverse"}
        sx={{ paddingBottom: "16px" }}
      >
        <Tooltip title="Download Report">
          <Button
            startIcon={<DownloadIcon />}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              marginLeft: "10px",
              paddingRight: "10px",
              width: "54px",
              height: "35px",
            }}
            onClick={onDownload}
          />
        </Tooltip>
        <div>
          <TextField
            type="text"
            name="location"
            label={`Location`}
            select
            variant="outlined"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            InputProps={{
              sx: {
                width: "108px",
                height: "35px",
                fontSize: "14px",
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "14px",
              },
            }}
            size="small"
          >
            {filterData?.allowedLocations?.length ? (
              <MenuItem value={"all"}>All</MenuItem>
            ) : (
              <MenuItem value={""}></MenuItem>
            )}
            {filterData?.allowedLocations?.length ? (
              filterData?.allowedLocations?.map((option: any, i: number) => (
                <MenuItem key={i} value={option._id}>
                  {option.name[0] + option.name.toLowerCase().slice(1)}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={""}></MenuItem>
            )}
          </TextField>
        </div>
      </Box>
    );
  };

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Master Schedule - 18 months summary"
            isAddBtn={false}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            // width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
            border: "1px solid #e0e0e07a",
          }}
        >
          <div
            style={{
              padding: "16px",
            }}
          >
            <CustomToolbar />
            <MaterialReactTable
              data={
                masterScheduleReports && masterScheduleReports?.length > 0
                  ? masterScheduleReports
                  : []
              }
              columns={columns}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableGlobalFilter={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableToolbarInternalActions={false}
              enableTopToolbar={false}
              enableStickyHeader
              enablePinning
              enableSorting={false}
              initialState={{ columnPinning: { left: ["trayName"] } }}
              manualPagination
              onPaginationChange={setPagination}
              rowCount={rowCount}
              state={{ pagination }}
              muiTablePaginationProps={{
                rowsPerPageOptions: [],
              }}
              muiTableProps={{
                sx: {
                  border: "1px solid #e0e0e07a",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  backgroundColor: "rgb(245, 246, 250)",
                  fontWeight: "600",
                  borderRight: "1px solid #e0e0e07a",
                  boxShadow: 0,
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  borderRight: "1px solid #e0e0e07a",
                  boxShadow: 0,
                },
              }}
            />
          </div>
        </div>
      </Container>
    </Box>
  );
};
