import { MRT_ColumnDef } from "material-react-table";
import { reformatDates } from "../../utils/reformatDates";

const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

const synopsisStyles = {
  display: "inline-block",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "150px",
  whiteSpace: "nowrap",
};

export const forwardMonthsColumns = (
  monthIndex: number,
  startDate: string,
  endDate: string
): MRT_ColumnDef[] => {
  startDate = reformatDates(startDate);
  endDate = reformatDates(endDate);
  const startMonthIndex = parseInt(startDate?.split("-")[1], 10) - 1;
  const endMonthIndex = parseInt(endDate?.split("-")[1], 10) - 1;

  const renderClickablecell2 = (params: any) => {
    const value = params.renderedCellValue;
    return (
      <span
        // @ts-ignore
        style={{ ...synopsisStyles, maxWidth: "unset" }}
        title={value}
      >
        {value}
      </span>
    );
  };

  const finalColums = [
    {
      accessorKey: "code",
      header: "CODE",
      size: 150,
      Cell: (params: any) => renderClickablecell2(params),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "name",
      header: "PRODUCT NAME",
      size: 250,
      Cell: (params: any) => renderClickablecell2(params),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "manufacturer",
      header: "MANUFACTURER",
      size: 150,
      Cell: (params: any) => renderClickablecell2(params),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ];

  const resultCulumn = [
    {
      accessorKey: "steel",
      header: "STEEL",
      size: 100,
      columns: [...finalColums],
      enableSorting: false,
    },
  ];

  const currentMonth = months[monthIndex];
  const startMonth = (!isNaN(startMonthIndex) &&
    months[startMonthIndex]) as string;
  const endMonth = (!isNaN(endMonthIndex) && months[endMonthIndex]) as string;

  const renderClickablecell = (params: any) => {
    const row = params.row.original;
    const cellData = params.cell.column?.id;
    const preFix = cellData.includes("Actual") ? "Forecast" : "Actual";
    const mth = cellData.substring(0, 3);
    const cellName = mth + preFix;
    const value = params.cell.getValue();
    return (
      <span
        className={value !== "-" && value < 0 ? "red" : ""}
        // @ts-ignore
        style={{ ...synopsisStyles, maxWidth: "55px" }}
        title={value}
      >
        {value}
      </span>
    );
  };

  const columnsHeaderBuilder = (index: number, total: number) => {
    for (let i = 0; i < total; i++) {
      const nextIndex = (index + i) % 12;
      const monthHeader = months[nextIndex];
      const newColums = [
        {
          accessorKey: `${monthHeader}Actual`,
          header: "ACTUAL",
          size: 75,
          enableSorting: false,
          Cell: (params: any) => renderClickablecell(params),
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
        },
        {
          accessorKey: `${monthHeader}Forecast`,
          header: "FORECAST",
          size: 75,
          enableSorting: false,
          Cell: (params: any) => renderClickablecell(params),
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
        },
      ];
      const newOBj = {
        accessorKey: `${monthHeader}`,
        header: monthHeader?.toUpperCase(),
        size: 75,
        columns: [...newColums],
        enableSorting: false,
      };
      resultCulumn.push(newOBj);
    }
  };

  if (startDate && !endDate) {
    //start date is given and no end date

    const index = months.indexOf(startMonth?.toLowerCase());
    columnsHeaderBuilder(index, 12);
  } else if (!startDate && endDate) {
    //no start date but with end date

    const index = months.indexOf(currentMonth?.toLowerCase());
    let endIndex = months.indexOf(endMonth?.toLowerCase());
    if (endIndex < index) {
      endIndex = endIndex + 12;
    }
    columnsHeaderBuilder(index, endIndex - index + 1);
  } else if (startDate && endDate) {
    //both are given

    const index = months.indexOf(startMonth?.toLowerCase());
    let endIndex = months.indexOf(endMonth?.toLowerCase());
    if (endIndex < index) {
      endIndex = endIndex + 12;
    }
    columnsHeaderBuilder(index, endIndex - index + 1);
  } else if (!startDate && !endDate) {
    const index = months.indexOf(currentMonth?.toLowerCase());
    columnsHeaderBuilder(index, 12);
  }
  return resultCulumn as MRT_ColumnDef[];
};
