import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import PageLoaderComponent from "./component/loader/PageLoaderComponent";
import ConfirmPwd from "./pages/confirmPassword/ConfirmPassword";
import HomeLayout from "./pages/home/HomeLayout";
import LoginPage from "./pages/login/SignIn";
import ResetPwd from "./pages/resetPassword/ResetPassword";
import { AppDispatch, RootState } from "./store/store";
import SnackbarComponent from "./component/snackbar/SnackbarComponent";
import { useRef, useState } from "react";
import "./App.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SteelScreenMain } from "./pages/steel";
import { TrayScreenMain } from "./pages/trayTypes";
import { InventoryScreen } from "./pages/inventory";
import { SteelPerTrayScreenMain } from "./pages/steelPerTray";
import { TotalRequirementScreen } from "./pages/totalRequirement";
import { SteelOnOrderMain } from "./pages/steelOnOrder";
import { ScheduleOrderMain } from "./pages/schedule";
import { SteelShortageScreen } from "./pages/reportsSteelShortage";
import { ShortageScreen } from "./pages/shortage";
import { MasterScheduleScreen } from "./pages/reportsMasterSchedule";
import { AdminScreenMain } from "./pages/admin";

function App() {
  const [isRefreshed, setIsRefreshed] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { globalAppState } = useSelector((state: RootState) => state);
  const { isAuthenticated, authToken, refreshToken } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const isRunned = useRef(false);

  const matches = useMediaQuery("(min-width:900px)");
  // useEffect(() => {
  //   if (!isRefreshed && authToken && refreshToken && isRunned.current) {
  //     const reqData = {
  //       accessToken: authToken,
  //       refreshToken: refreshToken,
  //     };
  //     // dispatch(refreshUserData(reqData));
  //     setIsRefreshed(true);
  //     return;
  //   }
  //   isRunned.current = true;
  // }, []);

  // useEffect(() => {
  //   if (!matches) {
  //     dispatch(triggrViewChange(true));
  //   } else {
  //     dispatch(triggrViewChange(false));
  //   }
  // }, [window.innerWidth]);

  return (
    <div className="App">
      <SnackbarComponent />
      <PageLoaderComponent />
      <div>
        {isAuthenticated ? (
          <div>
            <Routes>
              <Route path="/" element={<HomeLayout />}>
                <>
                  <Route index path="/" element={<SteelScreenMain />} />
                  <Route index path="/steel" element={<SteelScreenMain />} />
                  <Route index path="/asset" element={<TrayScreenMain />} />

                  <Route
                    index
                    path="/inventory"
                    element={<InventoryScreen />}
                  />
                  <Route
                    index
                    path="/steel-per-asset"
                    element={<SteelPerTrayScreenMain />}
                  />
                  <Route
                    index
                    path="/steel-on-order"
                    element={<SteelOnOrderMain />}
                  />
                  <Route
                    index
                    path="/schedule"
                    element={<ScheduleOrderMain />}
                  />
                  <Route
                    index
                    path="/user-management"
                    element={<AdminScreenMain />}
                  />
                  <Route
                    index
                    path="/total-requirement"
                    element={<TotalRequirementScreen />}
                  />
                  <Route
                    index
                    path="/steel-forecast-demand"
                    element={<SteelShortageScreen />}
                  />
                  <Route
                    index
                    path="/forecast-demand"
                    element={<ShortageScreen />}
                  />
                  {/*  */}
                  <Route
                    index
                    path="/master-schedule"
                    element={<MasterScheduleScreen />}
                  />
                  {/* <Route path="*" element={<SteelScreenMain />} /> */}
                  <Route path="*" element={<Navigate to="/" />} />
                </>
                <>
                  {/* <Route path="*" element={<SteelScreenMain />} /> */}
                  <Route path="*" element={<Navigate to="/" />} />
                </>
              </Route>
            </Routes>
          </div>
        ) : (
          <div>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/forgotpassword" element={<ConfirmPwd />} />
              <Route path="/resetpassword/:token" element={<ResetPwd />} />
              <Route path="/setpassword/:token" element={<ResetPwd />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
