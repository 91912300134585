import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Modal,
  Button,
  TableSortLabel,
} from "@mui/material";
import { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { deleteSteelOnOrder } from "../../api/steelonorder/Api.service";
import {
  Order,
  getComparator,
  stableSort,
} from "../steelPerTray/SteelPerTrayTable";
import { visuallyHidden } from "@mui/utils";
import React from "react";

const tableCells: any = [
  {
    id: "code",
    label: "CODE",
  },
  {
    id: "steelName",
    label: "PRODUCT NAME",
  },
  {
    id: "brand",
    label: "BRAND",
  },
  {
    id: "quantity",
    label: "QTY",
  },
  {
    id: "status",
    label: "STATUS",
  },
  {
    id: "purchaseDate",
    label: "PURCHASE DATE",
  },
  {
    id: "deliveryDate",
    label: "EXPECTED DELIVERY DATE",
  },
  {
    id: "outstandingQty",
    label: "OUTSTANDING QTY",
  },
  {
    id: "forecastedDate",
    label: "FORECASTED DATE",
  },
];

function EnhancedTableHead(props: any) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {tableCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: "600",
              backgroundColor: "#f5f6fa",
              border: "1px solid #e0e0e07a",
              borderBottom: "0",
              textAlign: "center",
            }}
          >
            {headCell.label && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                disabled={headCell.id === "action"}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const findThatStatus = (status: string) => {
  switch (status) {
    case "INCOMING_SHIPMENT":
      return "Incoming Shipment";
    case "BACK_ORDER":
      return "Back Order";
    case "ON_ORDER":
      return "On Order";
    case "FULFILLED":
      return "Fulfilled";
  }
};
export function SteelOnOrderRow(props: any): JSX.Element {
  const { row, index, refreshSteelOnOrder, onEdit } = props;
  const [open, setOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("code");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { masterLocation } = useSelector((state: any) => state.globalAppState);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () => stableSort(row?.steelUsed, getComparator(order, orderBy)),
    [order, orderBy, row?.steelUsed]
  );

  const collapsedStyles = {
    // width: "110px",
    // maxWidth: "150px",
    border: "1px solid #e0e0e07a",
    width: "100px !important",
    textAlign: "center",
  };

  const tableCellStyle = {
    // background: "red",
    borderRight: "1px solid #e0e0e07a",
    borderBottom: "1px solid #e0e0e07a",
    textAlign: "center",
  };

  const synopsisStyles = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100px",
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleClick = () => {
    setOpenDeleteModal(true);
  };

  const findThatSteel = (steelId: string) => {
    const steel = props?.steelListData?.find(
      (steel: any) => steel?._id === steelId
    );
    return `${steel?.name}`;
  };

  const locationName = row?.locationName;

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`uncollapsed-row-${index}`}
      >
        <TableCell sx={tableCellStyle}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ ...tableCellStyle, ...synopsisStyles }}
          title={row?.purchaseOrderNo}
        >
          {row?.purchaseOrderNo}
        </TableCell>
        <TableCell
          align="left"
          sx={{ ...tableCellStyle, ...synopsisStyles }}
          title={row?.supplier}
        >
          {row?.supplier}
        </TableCell>
        <TableCell
          align="left"
          sx={{ ...tableCellStyle, ...synopsisStyles }}
          title={row?.totalQty}
        >
          {row?.totalQty}
        </TableCell>
        <TableCell align="left" sx={tableCellStyle}>{`${
          locationName[0]
        }${locationName.slice(1).toLowerCase()}`}</TableCell>
        <TableCell
          align="left"
          sx={{ ...tableCellStyle, fontWeight: "600", borderRight: "0" }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip title="Edit">
              <IconButton
                onClick={() => onEdit(row?._id, row)}
                disabled={!props.permission}
              >
                <EditOutlinedIcon
                  color={props.permission ? "error" : "disabled"}
                ></EditOutlinedIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={handleClick} disabled={!props.permission}>
                <DeleteForeverOutlinedIcon
                  color={props.permission ? "error" : "disabled"}
                ></DeleteForeverOutlinedIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow key={`parent-collapsed-row-${index}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="collapsed emissions data">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={row?.length}
                />
                <TableBody>
                  {visibleRows?.map((collapsedRow: any) => (
                    <TableRow key={`rc-collapsed-row-${index}`}>
                      <TableCell
                        align="left"
                        sx={{ ...collapsedStyles, ...synopsisStyles }}
                        title={collapsedRow?.code}
                      >
                        {collapsedRow?.code}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{ ...collapsedStyles, ...synopsisStyles }}
                        title={findThatSteel(collapsedRow?.steelId)}
                      >
                        {findThatSteel(collapsedRow?.steelId)}
                      </TableCell>
                      <TableCell
                        align="left"
                        title={collapsedRow?.brand}
                        sx={{ ...collapsedStyles, ...synopsisStyles }}
                      >
                        {collapsedRow?.brand}
                      </TableCell>
                      <TableCell align="left" sx={collapsedStyles}>
                        {collapsedRow?.quantity}
                      </TableCell>
                      <TableCell align="left" sx={collapsedStyles}>
                        {findThatStatus(collapsedRow?.status)}
                      </TableCell>
                      <TableCell align="left" sx={collapsedStyles}>
                        {collapsedRow?.purchaseDate}
                      </TableCell>
                      <TableCell align="left" sx={collapsedStyles}>
                        {collapsedRow?.deliveryDate}
                      </TableCell>
                      <TableCell align="left" sx={collapsedStyles}>
                        {collapsedRow?.outstandingQty ?? `-`}
                      </TableCell>
                      <TableCell align="left" sx={collapsedStyles}>
                        {collapsedRow?.forecastedDate ?? `-`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverOutlinedIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Deleting this will impact the calculations. Are you sure you want
              to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteSteelOnOrder({
                      id: row?._id,
                      locationId: row?.locationId,
                      callback: refreshSteelOnOrder,
                    })
                  ).then(() => {
                    handleClose();
                  });
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
