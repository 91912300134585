import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { appSliceReducer } from "../domian/app/AppSlice";
import { TrayTypeReducer } from "../pages/trayTypes/slice/TrayTypesSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { SteelSliceReducer } from "../pages/steel/slice/SteelSlice";
import { AdminSliceReducer } from "../pages/admin/slice/AdminSlice";
import { SteelPerTraySliceReducer } from "../pages/steelPerTray/slice/SteelPerTraySlice";
import { ScheduleSliceReducer } from "../pages/schedule/slice/ScheduleSlice";
import { SteelOnOrderSliceReducer } from "../pages/steelOnOrder/slice/SteelOnOrderSlice";
import { InventoryReducer } from "../pages/inventory/slice/InventorySlice";
import { ShortageSliceReducer } from "../pages/shortage/slice/SteelSlice";
import { TotalRSliceReducer } from "../pages/totalRequirement/slice/TotalRSlice";
import { SteelShortageReducer } from "../pages/reportsSteelShortage/slice/SteelShortageReportsSlice";
import { MasterScheduleReportsReducer } from "../pages/reportsMasterSchedule/slice/MasterScheduleReportsSlice";

const persistConfig = {
  key: "root",
  storage,
};

const combinedReducer = combineReducers({
  globalAppState: appSliceReducer,
  steelState: SteelSliceReducer,
  trayTypeState: TrayTypeReducer,
  steelPerTrayState: SteelPerTraySliceReducer,
  scheduleState: ScheduleSliceReducer,
  steelOnOrderState: SteelOnOrderSliceReducer,
  inventoryState: InventoryReducer,
  shortageState: ShortageSliceReducer,
  TotalRState: TotalRSliceReducer,
  steelShortageReportsState: SteelShortageReducer,
  masterScheduleReportsState: MasterScheduleReportsReducer,
  adminState: AdminSliceReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "SIGNOUT_REQUEST") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>; //contains the state of the whole store
export type AppDispatch = typeof store.dispatch; //use this when you need to dispatch any action in lower components
