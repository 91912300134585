import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    indicator: PaletteColor;
  }

  interface PaletteOptions {
    indicator: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B3644",
    },
    secondary: {
      main: "#4C4D4F",
    },
    text: {
      primary: "#1B3644",
      secondary: "#4C4D4F",
    },
    common: {
      black: "#000000",
    },
    indicator: {
      main: "#821B28",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedPrimary: {
          backgroundColor: "#D29D4A",
          color: "#FFFFFF",
        },
        outlinedSecondary: {
          color: "#D29D4A",
          borderColor: "#D29D4A",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#821B28",
          textDecoration: "none",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // maxWidth: "calc(100% - 50px) !important",
          // transform: "translate(14px, 9px) scale(1) !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          // transform: "translate(14px, -9px) scale(0.75) !important",
        },
      },
    },
  },
});

export default theme;
