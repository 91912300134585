import { createSlice } from "@reduxjs/toolkit";
import {
  getMasterScheduleReportsListing,
  getmasterSchedukReportsFilterData,
} from "../../../api/reports/masterScheduleReports/MasterScheduleReports.service";
interface MasterScheduleReportsState {
  masterScheduleReports: any;
  rowCount: number;
  filterData: any;
}

const initialState: MasterScheduleReportsState = {
  masterScheduleReports: {},
  rowCount: 0,
  filterData: {},
};

const MasterScheduleReportsSlice = createSlice({
  name: "MasterScheduleReportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getMasterScheduleReportsListing.fulfilled,
      (state, { payload }) => {
        state.masterScheduleReports = payload?.finalResponse;
        state.rowCount = payload?.count;
      }
    );
    builder.addCase(
      getmasterSchedukReportsFilterData.fulfilled,
      (state, { payload }) => {
        state.filterData = payload;
      }
    );
  },
});

export const MasterScheduleReportsReducer = MasterScheduleReportsSlice.reducer;
