import { PopUpMain } from "../../component/popUp";
import {
  Box,
  Button,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import {
  addSteelPerTray,
  editSteelPerTray,
} from "../../api/steelpertray/Api.service";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AppDispatch } from "../../store/store";
import { initialSteelPerTrayData } from "./data";
import { deleteAndShift } from "../../utils/deleteAndShift";
import { VirtualizeCode } from "./Virtualize";

export const AddSteelPerTray = (props: any): JSX.Element => {
  const { title = "Add Steel" } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"lg"}>
        <Box>
          <AddSteelPerTrayScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
};

export const AddSteelPerTrayScreen = (props: any): JSX.Element => {
  const emptySteelObject = {
    steelId: "",
    code: "",
    width: "",
    length: "",
    quantity: "",
  };

  const {
    handleClose,
    editData,
    refreshSteelPerTray,
    steelListData,
    traysListData,
  } = props;

  const dispatch: AppDispatch = useDispatch();

  const [product, setProduct] = React.useState({});
  const [value, setValue] = React.useState<any>({}); //selected
  const [inputValue, setInputValue] = React.useState<any>({}); //typed

  const handleChange = (
    event: SelectChangeEvent<string>,
    fieldName: any,
    Name: any,
    index: any
  ) => {
    let {
      target: { value },
    } = event;

    if (Name.includes("code")) {
      // empty other fields on changing code
      fieldName(`steelUsed[${index}].steelId`, "");
      fieldName(`steelUsed[${index}].length`, "");
      fieldName(`steelUsed[${index}].width`, "");
      fieldName(`steelUsed[${index}].quantity`, "");
      const resp = steelListData.filter((steel: any) => steel.code === value);
      value = resp[0]?.id;
      fetchSteel(value, index);
    }

    if (Name) fieldName(Name, value);
  };

  const fetchSteel = (value: any, index: number) => {
    const resp = steelListData.filter((steel: any) => steel.id === value);
    const tempKey = `index-${index}`;
    setProduct({
      ...product,
      [tempKey]: resp,
    });
  };

  const handleCallBack = () => {
    refreshSteelPerTray();
    handleClose();
  };

  React.useEffect(() => {
    if (editData?.trayId) {
      const editSteel = editData.steelUsed.reduce(
        (acc: any, steel: any, index: number) => {
          const response = steelListData.filter(
            (steelData: any) => steelData._id === steel.steelId
          );
          const tempKey = `index-${index}`;
          acc[tempKey] = response;
          return acc;
        },
        {}
      );
      setProduct(editSteel);
      const editCode = editData.steelUsed.reduce(
        (acc: any, steel: any, index: number) => {
          const tempKey = `index-${index}`;
          acc[tempKey] = steel.code;
          return acc;
        },
        {}
      );
      setValue(editCode);
      setInputValue(editCode);
    }
  }, [editData, steelListData]);

  return (
    <React.Fragment>
      <>
        {false ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5" align="left">
                    Asset type
                  </Typography>
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "5vh", width: "50%" }}
                    animation={"wave"}
                  />
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="subtitle1" align="left">
                    Add steel and quantity for each asset:
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Formik
              initialValues={
                editData?.trayId
                  ? editData
                    ? editData
                    : initialSteelPerTrayData
                  : initialSteelPerTrayData
              }
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                const temp = values.steelUsed.map((steel: any) => ({
                  steelId: steel.steelId,
                  quantity: steel.quantity,
                }));

                const tempObject = {
                  trayId: values.trayId,
                  steelUsed: temp,
                };

                const data = {
                  data: tempObject,
                  callback: handleCallBack,
                };
                console.log("GETDATA123", data.data);
                if (editData?.trayId) {
                  const updateData = {
                    data: { ...tempObject, trayId: editData?.trayId },
                    callback: handleCallBack,
                  };
                  dispatch(editSteelPerTray(updateData));
                } else {
                  dispatch(addSteelPerTray(data));
                }
              }}
            >
              {({ values, resetForm, isSubmitting, errors, setFieldValue }) => (
                <>
                  <Box
                    ml={0}
                    sx={{
                      p: 3,
                      backgroundColor: "#fff ",
                    }}
                  >
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography sx={{ marginTop: "10px" }} align="center">
                            Asset Name
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                          <Field
                            component={TextField}
                            type="text"
                            value={values?.trayId}
                            label="Asset Name"
                            select
                            variant="outlined"
                            fullWidth
                            required
                            size="small"
                            disabled={editData?.trayId}
                            onChange={(event: any) =>
                              handleChange(
                                event,
                                setFieldValue,
                                `trayId`,
                                undefined
                              )
                            }
                          >
                            {traysListData?.map((option: any) => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "30px",
                          marginLeft: "0",
                        }}
                      >
                        <Typography variant="subtitle1" align="left">
                          Add steel and quantity for each asset:
                        </Typography>
                      </Grid>
                      <Grid>
                        <FieldArray name="steelUsed">
                          {({ push, remove }) => (
                            <React.Fragment>
                              {values?.steelUsed?.map((_: any, index: any) => (
                                <div className="add-more-container-a">
                                  <Grid container item key={index} spacing={2}>
                                    <Grid item xs={2} sm={2} md={2}>
                                      <VirtualizeCode
                                        options={steelListData.map(
                                          (s: any) => s.code
                                        )}
                                        originalValue={value}
                                        value={value[`index-${index}`] ?? ""}
                                        setValue={setValue}
                                        originalInputValue={inputValue}
                                        inputValue={
                                          inputValue[`index-${index}`] ?? ""
                                        }
                                        setInputValue={setInputValue}
                                        index={index}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                      />
                                      {/* <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={values?.steelUsed[index]?.code}
                                        placeholder="Code"
                                        label="Code"
                                        className="custom-select"
                                        isMulti={true}
                                        required
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].code`,
                                            index
                                          )
                                        }
                                      >
                                        {steelListData?.map((option: any) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option?.code}
                                          </MenuItem>
                                        ))} 
                                      </Field>*/}
                                    </Grid>
                                    <Grid item xs={3.5} sm={3.5} md={3.5}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={
                                          values?.steelUsed[index]?.steelId
                                        }
                                        label="Product Name"
                                        required
                                        placeholder="Product Name"
                                        className="custom-select"
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].steelId`,
                                            index
                                          )
                                        }
                                      >
                                        {Object.keys(product).length ? (
                                          // @ts-ignore
                                          product[`index-${index}`]?.map(
                                            (option: any) => {
                                              return (
                                                <MenuItem
                                                  key={option?.id}
                                                  value={option?.id}
                                                >
                                                  {option?.name}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        ) : (
                                          <MenuItem key={"random"} value={""}>
                                            {""}
                                          </MenuItem>
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={1.5} sm={1.5} md={1.5}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={values?.steelUsed[index]?.length}
                                        placeholder="Length"
                                        required
                                        label="Length"
                                        className="custom-select"
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].length`,
                                            index
                                          )
                                        }
                                      >
                                        {Object.keys(product).length ? (
                                          // @ts-ignore
                                          product[`index-${index}`]?.map(
                                            (option: any) => {
                                              return (
                                                <MenuItem
                                                  key={option?.id}
                                                  value={option?.length}
                                                >
                                                  {option?.length}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        ) : (
                                          <MenuItem key={"random"} value={""}>
                                            {""}
                                          </MenuItem>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        className="error-class-message"
                                        component="div"
                                        name={`steelUsed[${index}].length`}
                                      />
                                    </Grid>
                                    <Grid item xs={1.5} sm={1.5} md={1.5}>
                                      <Field
                                        component={TextField}
                                        select
                                        type="text"
                                        value={values?.steelUsed[index]?.width}
                                        placeholder="Width"
                                        required
                                        label="Width"
                                        className="custom-select"
                                        isMulti={true}
                                        size="small"
                                        fullWidth
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].width`,
                                            index
                                          )
                                        }
                                      >
                                        {Object.keys(product).length ? (
                                          // @ts-ignore
                                          product[`index-${index}`]?.map(
                                            (option: any) => {
                                              return (
                                                <MenuItem
                                                  key={option?.id}
                                                  value={option?.width}
                                                >
                                                  {option?.width}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        ) : (
                                          <MenuItem key={"random"} value={""}>
                                            {""}
                                          </MenuItem>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        className="error-class-message"
                                        component="div"
                                        name={`steelUsed[${index}].width`}
                                      />
                                    </Grid>
                                    <Grid item xs={1.5} sm={1.5} md={1.5}>
                                      <Field
                                        fullWidth
                                        size="small"
                                        id={`steelUsed[${index}].quantity`}
                                        name={`steelUsed[${index}].quantity`}
                                        value={values.steelUsed[index].quantity}
                                        component={TextField}
                                        type="text"
                                        required
                                        label="Quantity"
                                        onChange={(event: any) =>
                                          handleChange(
                                            event,
                                            setFieldValue,
                                            `steelUsed[${index}].quantity`,
                                            index
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2}>
                                      <div className="remove-add-more-item">
                                        {values?.steelUsed.length > 1 ? (
                                          <IconButton
                                            onClick={() => {
                                              remove(index);
                                              deleteAndShift(product, index);
                                              const temp = deleteAndShift(
                                                value,
                                                index
                                              );
                                              setValue({
                                                ...temp,
                                              });
                                              const temp1 = deleteAndShift(
                                                inputValue,
                                                index
                                              );
                                              setInputValue({
                                                ...temp1,
                                              });
                                            }}
                                          >
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}

                              <Grid item>
                                {typeof errors.steelUsed === "string" ? (
                                  <Typography color="error">
                                    {errors.steelUsed}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginBottom: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <IconButton
                                  disabled={isSubmitting}
                                  onClick={() => push(emptySteelObject)}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              </div>
                            </React.Fragment>
                          )}
                        </FieldArray>
                      </Grid>
                      <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {"Save"}
                        </Button>
                        <Button onClick={handleClose} variant="outlined">
                          Cancel
                        </Button>
                        {editData?.trayId ? (
                          <></>
                        ) : (
                          <Button
                            variant="outlined"
                            type="reset"
                            color="error"
                            onClick={(e) => {
                              setProduct({});
                              setValue({});
                              setInputValue({});
                              resetForm();
                            }}
                          >
                            Reset
                          </Button>
                        )}
                      </Box>
                    </Form>
                  </Box>
                </>
              )}
            </Formik>
          </>
        )}
      </>
    </React.Fragment>
  );
};
