import axios from "axios";
import { SignoutTrigger, toggleSnacker } from "../domian/app/AppSlice";
import { store } from "../store/store";
import { errorOpen } from "./Api.service";
const envCollection = ["DEV", "STAGE", "PROD", "LOCALHOST"];
const url = window.location.href;

export let appEnvUrl = envCollection.filter((e: any) => {
  return url.includes(e.toLowerCase());
});

export const finalUrl =
  appEnvUrl[0] === "DEV"
    ? process.env.REACT_APP_API_BASE_URL_DEV
    : appEnvUrl[0] === "STAGE"
    ? process.env.REACT_APP_API_BASE_URL_STAGE
    : appEnvUrl[0] === "PROD"
    ? process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL_LOCALHOST;

const API = axios.create({
  baseURL: finalUrl,
});
API.interceptors.request.use((config) => {
  let token = store.getState().globalAppState.authToken;
  if (config.headers) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});
API.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const errCode = [401];
    if (errCode.includes(err.response.status)) {
      //localStorage authToken should clear
      // store.dispatch(logOut)
      store.dispatch(SignoutTrigger());
      store.dispatch({ type: "SIGNOUT_REQUEST" });
      store.dispatch(
        toggleSnacker(errorOpen("The Session Is Expired Please Login Again"))
      );
      // navigate("/");
      // eslint-disable-next-line no-restricted-globals
      // location.href = "/";
      // return false;
    }
    throw err;
  }
);
export default API;
