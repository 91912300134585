import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_STEEL,
  DELETE_STEEL,
  GET_ALL_STEEL,
  GET_DROPDOWN_STEEL,
  GET_EDIT_STEEL,
  UPDATE_STEEL,
} from "../ActionConstants";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";

export const getSteelList = createAsyncThunk(
  GET_ALL_STEEL,
  async (postData: any, thunkApi) => {
    const { grade, thickness, width, length, page, pageSize, code } = postData;
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steel?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${grade ? `grade=${grade}&` : ""}${
        thickness ? `thickness=${thickness}&` : ""
      }${width ? `width=${width}&` : ""}${length ? `length=${length}&` : ""}${
        code ? `code=${code}&` : ""
      }`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );
      return finelObj;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Steel Data ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const addSeel = createAsyncThunk(
  ADD_STEEL,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post("steel", postData.data, { signal });
      if (response) {
        postData?.callback();
        const data = response?.data?.result;
        dispatch(toggleSnacker(successOpen("Steel Added Successfully!")));
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Saving Steel, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getEditSeel = createAsyncThunk(
  GET_EDIT_STEEL,
  async (id: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(`steel/?steelId=${id}`, { signal });
      if (response) {
        const data = response?.data?.data;
        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Steel, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const updateSeel = createAsyncThunk(
  UPDATE_STEEL,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.patch("steel", postData?.data, { signal });
      if (response) {
        postData?.callback();
        const data = response?.data?.result;
        dispatch(toggleSnacker(successOpen("Steel Edited Successfully!")));
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Saving Steel, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteSteel = createAsyncThunk(
  DELETE_STEEL,
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(`steel?steelId=${postData?.steelId}`, {
        signal,
      });
      postData.callback();
      const data = response?.data;
      if (data?.status === 0) dispatch(toggleSnacker(errorOpen(data?.message)));
      else {
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "Steel has been deleted successfully!"
            )
          )
        );
      }
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Deleting Steel, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getFilterData = createAsyncThunk(
  GET_DROPDOWN_STEEL,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steel/dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, { signal });
      const data = {
        ...response?.data?.data,
        location: response?.data?.data?.allowedLocations,
      };
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Steel Data ...!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
