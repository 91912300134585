import {
  Button,
  Card,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { QuickSearchToolbarProps } from "../../component/search/types";
import theme from "../../Themes";

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { filterData } = useSelector((state: RootState) => state.steelState);
  const { setSteelData, steelData } = props;

  const updateSteelData = (value: string | number, name: any) => {
    setSteelData({ ...steelData, [name]: value !== "All" ? value : "" });
  };

  const clearAllFilters = () => {
    setSteelData({
      code: "",
      grade: "",
      thickness: "",
      width: "",
      length: "",
    });
  };
  if (filterData?.allowedLocations?.length) {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pr={2}
      >
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="code"
              label={`Code`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.code}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.code?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.code?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="grade"
              label={`Grade`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.grade}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.grades?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.grades?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="thickness"
              label={`Thickness`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.thickness}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.thickness?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.thickness?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="length"
              label={`Length`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.length}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.length?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.length?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="width"
              label={`Width`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.width}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {" "}
              {filterData?.width?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.width?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const SteelFilters = (props: QuickSearchToolbarProps) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
