import { Box, Button, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const MainScreenHeader = ({
  onClickHandle,
  headerName,
  addBtnTitle = "Add",
  isAddBtn = true,
  disabled = false,
  helperLabel = "",
}: any): JSX.Element => {
  const { isMobileView } = useSelector((state: any) => state.globalAppState);
  return (
    <div>
      <Box component="main">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              }}
              spacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
            >
              <Typography
                variant="h5"
                sx={{
                  maxWidth: { lg: "100%", xl: "none" },
                  fontWeight: "500",
                }}
              >
                {headerName}
              </Typography>
            </Stack>
            <div>
              {isAddBtn && (
                <Button
                  style={{}}
                  variant="contained"
                  onClick={onClickHandle}
                  color="primary"
                  disabled={disabled}
                >
                  {addBtnTitle}
                </Button>
              )}
            </div>
          </Stack>
          {helperLabel && (
            <Stack
              sx={{
                marginTop: "0px !important",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "400",
                }}
              >
                {helperLabel}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default MainScreenHeader;
