import { createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import {
  SignoutTrigger,
  toggleLoader,
  toggleSnacker,
} from "../domian/app/AppSlice";
import {
  LOGIN,
  REFRESH_TOKEN,
  FORGOT_PWD,
  RESET_PWD,
  CREATE_PWD,
  VERIFY_PWD_LINK,
} from "./ActionConstants";
import API from "./Api.utils";
import { LOGIN_URL } from "./Constant";
import { ResetPasswordPayload } from "../pages/resetPassword/ResetPassword";

export const openLoaderPayload = {
  isLoading: true,
};
export const closeLoaderPayload = {
  isLoading: false,
};

export const successOpen = (msg: string) => {
  return {
    isOpen: true,
    variant: "success",
    message: msg ? msg : "successfully done ...!",
  };
};
export const errorOpen = (msg: string) => {
  return {
    isOpen: true,
    variant: "error",
    message: msg ? msg : "err done ...!",
  };
};
export const closeSnackbar = {
  isOpen: false,
  variant: "",
  message: "",
};

export const authLogin = createAsyncThunk(
  LOGIN,
  async (postData: object, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(LOGIN_URL, postData, { signal });
      if (response.data.message[0].msg === "Invalid password.") {
        dispatch(toggleSnacker(errorOpen(response.data.message[0].msg)));
        return;
      }
      if (response.data.message === "User does not exist.") {
        dispatch(toggleSnacker(errorOpen("Email does not exist.")));
        return;
      }
      if (
        response.data.message ===
        "User is inactive. Ask Admin to active the user."
      ) {
        dispatch(
          toggleSnacker(
            errorOpen("User is inactive. Ask Admin to active the user.")
          )
        );
        return;
      }
      if (
        response.data.message ===
        "Please confirm the email by clicking on the link provided in the email."
      ) {
        dispatch(
          toggleSnacker(
            errorOpen(
              "Please confirm the email by clicking on the link provided in the email."
            )
          )
        );
        return;
      }
      const decoded: any = jwt_decode(response.data.accessToken);

      const fullName: string = `${response?.data?.data?.firstName} ${response?.data?.data?.lastName}`;
      const Role = 2;
      const ID = response?.data?.data?._id;
      const jobRole = response?.data?.data?.role;
      const allowedLocations = response?.data?.data?.allowedLocations;
      const ownLocation = {
        name: response?.data?.data?.location?.name,
        locationId: response?.data?.data?.locationId,
      };
      const payloadData: any = {
        userInfo: {
          fullName,
          Role,
          ID,
          allowedLocations,
          ownLocation,
          jobRole,
        },
        authToken: response?.data?.accessToken,
        refreshToken: response?.data?.refreshToken,
        expiryTime: decoded?.exp,
      };

      dispatch(toggleSnacker(successOpen("You are successfully logged in!")));
      return payloadData;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.message[0]?.msg
              ? err?.response?.data?.message[0]?.msg
              : "Error, please try signing in again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const refreshUserData = createAsyncThunk(
  REFRESH_TOKEN,
  async (refreshData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        "Authentication/refreshToken",
        refreshData,
        { signal }
      );
      // logic to get data info
      const decoded = jwt_decode(response.data.token);

      const { fullName, Role, ID }: any = decoded;
      const payloadData: any = {
        userInfo: { fullName, Role, ID },
        authToken: response.data.token,
        refreshToken: response.data.refreshToken,
        expiryTime: response.data.expires_in,
      };
      // dispatch(toggleSnacker(successOpen("Sucessfully refreshed token")));
      return payloadData;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error while refreshing token...!"
          )
        )
      );
      dispatch(SignoutTrigger());
      // dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const forgotPassword = createAsyncThunk(
  FORGOT_PWD,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(`forgotPassword`, postData.values, {
        signal,
      });
      const data = response.data;
      if (data.message === "User not found." && data.status === 0) {
        dispatch(
          toggleSnacker(
            errorOpen(data?.message ? data?.message : "User not found.")
          )
        );
        return;
      } else if (data.status === 0 && data.message === "Link is expired.") {
        dispatch(
          toggleSnacker(
            errorOpen(data?.message ? data?.message : "Link is expired.")
          )
        );
        return;
      } else {
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "Email has been sent successfully. Please check your mail for verification link."
            )
          )
        );
      }

      postData.callback();
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error, please try again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const resetPassword = createAsyncThunk(
  RESET_PWD,
  async (postData: ResetPasswordPayload, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(`resetPassword`, postData.data, {
        signal,
      });
      const data = response.data;
      postData.callback();
      if (data.status === 0 && data.message === "Link is expired.") {
        dispatch(
          toggleSnacker(
            errorOpen(data?.message ? data?.message : "Link is expired.")
          )
        );
        return;
      } else {
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "Password has been updated successfully"
            )
          )
        );
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error please try again in sometime"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const createPassword = createAsyncThunk(
  CREATE_PWD,
  async (postData: ResetPasswordPayload, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(`setPassword`, postData.data, {
        signal,
      });
      const data = response.data;
      postData.callback();
      if (data.status === 0 && data.message === "Link is expired.") {
        dispatch(
          toggleSnacker(
            errorOpen(
              data?.responseMessage ? data?.responseMessage : "Link is expired."
            )
          )
        );
      } else {
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "Password has been updated successfully"
            )
          )
        );
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error please try again in sometime"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const verifyResetPasswordLink = createAsyncThunk(
  VERIFY_PWD_LINK,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        `Account/CheckPasswordLinkValidity`,
        {
          encrypted_Request_Id: postData.data,
        },
        {
          signal,
        }
      );
      if (response.data.result === false) {
        // dispatch(toggleSnacker(errorOpen("Link Expired!")));
        return true;
      } else {
        dispatch(toggleSnacker(successOpen("Link verification successful!")));
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error please try again in sometime"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
