import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
import { AddTrayType } from "./AddTrayType";
import { TrayTypesFilters } from "./Filters";
import { clearEditData } from "./slice/TrayTypesSlice";
import {
  deleteTrayTypes,
  getEditTrayData,
  getTrayTypesListing,
} from "../../api/tray-types-api/TrayType.service";
import { checkPermission } from "../../utils/checkPermission";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export interface GetTrayTypesPayload {
  Page: number | string;
  PageSize: number | string;
  thickness: number | string;
}

export interface DeleteTrayTypesPayload {
  trayId: string;
}

export interface AddTrayTypesCollection {
  name: string;
  type: string;
  steelGrade: string;
  thickness: number | string;
  width: number | string;
  lenght: number | string;
  code: string;
}

export interface EditTrayTpePayload {
  data: DeleteTrayTypesPayload & AddTrayTypesCollection;
  callback: Function;
}

export interface AddTrayTypePayload {
  data: AddTrayTypesCollection;
  callback: Function;
}

export const TrayScreenMain = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);

  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setEditTrayData({ _id: "" });
    dispatch(clearEditData());
  };

  const dispatch: AppDispatch = useDispatch();
  const { trays, totalRows } = useSelector((state: any) => state.trayTypeState);

  const { isMobileView, masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [editTrayData, setEditTrayData] = useState({ _id: "" });
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [permission, setPermission] = useState(() => {
    const getPermission = checkPermission(
      masterLocation?.locationId,
      userInfo?.ownLocation?.locationId as string,
      userInfo?.jobRole?.key as string,
      "Assets"
    );
    return getPermission;
  });

  useEffect(() => {
    refreshTrayData();
  }, [pageState]);

  const refreshTrayData = () => {
    dispatch(
      getTrayTypesListing({
        name: searchText,
        page: pageState.page,
        pageSize: pageState.pageSize,
      })
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const refreshOnSearch = (searchString: string) => {
    dispatch(
      getTrayTypesListing({
        name: searchString,
        page: pageState.page,
        pageSize: pageState.pageSize,
      })
    );
  };

  const onSearch = (value: string) => {
    setSearchText(value);
    const payload = {
      name: value,
    };
    dispatch(getTrayTypesListing(payload)).then(() => refreshOnSearch(value));
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "ASSET NAME",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          let currentRow = params.row;

          setDeletId(currentRow._id);
          handleOpen();
        };
        const onEdit = (e: any) => {
          const currentRow = params.row;

          setEditTrayData(currentRow);

          dispatch(getEditTrayData(currentRow?._id)).then(() => {
            setAddOpen(true);
          });
        };

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="Edit">
              <IconButton disabled={!permission}>
                <EditOutlinedIcon
                  color={permission ? "error" : "disabled"}
                  onClick={onEdit}
                ></EditOutlinedIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton disabled={!permission}>
                <DeleteForeverOutlinedIcon
                  color={permission ? "error" : "disabled"}
                  onClick={onClick}
                ></DeleteForeverOutlinedIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const getRowId = (row: any) => {
    return row._id;
  };

  const triggerNavigateNormal = () => {
    refreshTrayData();
    handleAddClose();
  };

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Assets"
            addBtnTitle="Add Asset"
            // headerName="Tray"
            // addBtnTitle="Add New Tray"
            disabled={!permission}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          <DataGrid
            sx={{
              // border: 1,
              padding: "16px",
              borderColor: "#e0e0e07a",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeader": {
                position: "unset",
              },
              "& .MuiDataGrid-cell a": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f6fa",
                borderTop: "2px solid #e0e0e07a",
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600 !important",
              },
              "& .MuiDataGrid-cell:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-cell": {
                borderRight: "1px solid #e0e0e07a",
              },

              "& .MuiDataGrid-columnHeaders": {
                border: "0",
              },
              "& .MuiDataGrid-columnHeadersInner": {
                borderBottom: "1px solid #e0e0e07a !important",
              },
              "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
                {
                  display: "none",
                },
              "& .MuiDataGrid-row .MuiDataGrid-cell:empty": {
                border: 0,
                background: "white",
              },
            }}
            rows={trays?.length > 0 ? trays : []}
            columns={columns}
            getRowId={getRowId}
            density={"standard"}
            disableColumnMenu={true}
            components={{
              LoadingOverlay: SkeletonLoadingOverlay,
              Toolbar: TrayTypesFilters,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Records Available
                </Stack>
              ),
            }}
            disableSelectionOnClick={true}
            rowCount={totalRows}
            rowsPerPageOptions={[]}
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            componentsProps={{
              toolbar: {
                onSearchFn: onSearch,
                searchText: searchText,
                setSearchText: setSearchText,
                componentName: "Asset",
              },
            }}
          />
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverOutlinedIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(deleteTrayTypes(deletId)).then(() =>
                    refreshTrayData()
                  );

                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddTrayType
        open={addOpen}
        handleClose={handleAddClose}
        editData={editTrayData}
        title={editTrayData?._id ? "Edit Asset" : "Add Asset"}
        triggerNavigateNormal={triggerNavigateNormal}
      />
    </Box>
  );
};
