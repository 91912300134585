export const intialSteelData = {
  trayId: "",
  customerName: "",
  quantity: "",
  probability: "",
  purchaseDate: "",
  deliveryDate: "",
  processingDate: "",
  kdNumber: "",
};

export const initialScheduleSummaryData = {
  id: "",
  trayType: "",
  jan: "",
  feb: "",
  mar: "",
  apr: "",
  may: "",
  jun: "",
  jul: "",
  aug: "",
  sep: "",
  oct: "",
  nov: "",
  dec: "",
};
