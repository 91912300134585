import {
    Button, Divider
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from "@mui/system";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './style.css'

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

export function PopUpMain(props: any) {
    const { title, children, handleClose, open, saveBtnTitle = "Save", saveHandler, resetHandler, resetTitle = "Reset", closeTitle = "Close", size = "sm" } = props
    return (
        <Box>
            <Dialog onClose={handleClose} open={open} fullWidth
                maxWidth={size}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pr={2}>

                    <DialogTitle>{title}</DialogTitle>
                    <HighlightOffIcon fontSize="large" onClick={handleClose} className="close-icon"></HighlightOffIcon>
                </Box>
                <Box pb={2} px={2} className="popUpMain">
                    <Divider />
                    {
                        children
                    }
                </Box>
            </Dialog>
        </Box>
    );
}
