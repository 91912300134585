import {
  GET_ALL_INVENTORY,
  ADD_INVENTORY,
  GET_EDIT_INVENTORY,
  GET_DROPDOWN_INVENTORY,
  GET_ALL_LIST_INVENTORY,
} from "../ActionConstants";

import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../Api.utils";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import { inventoryAddIsLoading } from "../../pages/inventory/slice/InventorySlice";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { reformatDates } from "../../utils/reformatDates";

export const getInventoryListing = createAsyncThunk(
  GET_ALL_INVENTORY,
  async (payload: any, thunkApi) => {
    let {
      brand,
      code,
      discrepancy,
      endDate,
      name,
      startDate,
      pageIndex,
      pageSize,
      location,
      grade,
      range,
    } = payload;

    startDate = reformatDates(startDate);
    endDate = reformatDates(endDate);

    //const token = authlogin
    const { rejectWithValue, signal, dispatch } = thunkApi;

    try {
      const codeUrl = generateCodeUrl(code);
      const url = `inventory?page=${pageIndex}&${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${name ? `name=${name}&` : ""}${grade ? `grade=${grade}&` : ""}${
        brand ? `brand=${brand}&` : ""
      }${codeUrl}${discrepancy ? `discrepancy=${discrepancy}&` : ""}${
        startDate ? `startDate=${startDate}&` : ""
      }${endDate ? `endDate=${endDate}&` : ""}${
        range ? `range=${range}&` : ""
      }${location !== "all" ? `locationId=${location}&` : ""}`;

      // return {} as any;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      if (response) {
        // dispatch(inventoryAddIsLoading(false));
        const rows = response.data.data.rows.map((steel: any) => ({
          ...steel,
          // name: `${steel.name}, ${steel.length}mm, ${steel.width}mm`,
        }));
        response.data.data.rows = rows;
        return response?.data?.data;
      }
    } catch (err: any) {
      // dispatch(inventoryAddIsLoading(false));
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Inventory Data ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      // dispatch(inventoryAddIsLoading(false));
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const addInventory = createAsyncThunk(
  ADD_INVENTORY,
  async (postData: any, thunkApi) => {
    //const token = authLogin
    const { rejectWithValue, signal, dispatch } = thunkApi;
    dispatch(toggleLoader(openLoaderPayload));

    if (postData.steelUsed.length === 0) {
      dispatch(
        toggleSnacker(errorOpen("Please enter quantity before submitting!"))
      );
      return;
    }
    try {
      const response = await API.post(`inventory/add`, postData, {
        signal,
      });

      if (response && response.status === 200) {
        dispatch(toggleSnacker(successOpen("Inventory Added Successfully!")));
        return response?.data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Adding Inventory Data, Please try again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getEditInventoryData = createAsyncThunk(
  GET_EDIT_INVENTORY,
  async (id: any, thunkApi) => {
    //const token = asyncToken
    const { rejectWithValue, signal, dispatch } = thunkApi;

    try {
      const response = await API.get(`inventory?inventoryId=${id}`, { signal });

      return response?.data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Inventory Data , Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getInventoryFilterData = createAsyncThunk(
  GET_DROPDOWN_INVENTORY,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `inventory/dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, { signal });
      const data = {
        ...response?.data?.data,
        location: response?.data?.data?.allowedLocations,
      };
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Inventory Filter Data ...!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const getInventoryAllListing = createAsyncThunk(
  GET_ALL_LIST_INVENTORY,
  async (payload: any, thunkApi) => {
    //const token = authlogin
    const { date } = payload;
    const { rejectWithValue, signal, dispatch } = thunkApi;

    try {
      dispatch(inventoryAddIsLoading(true));
      const url = `inventory/addList?${date ? `date=${date}` : ""}`;

      // return {} as any;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      if (response) {
        dispatch(inventoryAddIsLoading(false));
        return response?.data?.data;
      }
    } catch (err: any) {
      dispatch(inventoryAddIsLoading(false));
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Inventory Data ...!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
    } finally {
      dispatch(inventoryAddIsLoading(false));
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
