import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Box,
  Button,
  Container,
  Modal,
  TablePagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { AddSteelPerTray } from "./AddSteelPerTray";
import { SteelPerTrayTable } from "./SteelPerTrayTable";
import { clearEditData } from "./slice/SteelPerTraySlice";
import {
  getFilterData,
  getSteelPerTrayList,
  getEditSteelPerTray,
} from "../../api/steelpertray/Api.service";
import { getSteelList } from "../../api/steel/Api.service";
import { getTrayTypesListing } from "../../api/tray-types-api/TrayType.service";
import { SteelPerTrayFilters } from "./SteelPerTrayFilters";
import { checkPermission } from "../../utils/checkPermission";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export const SteelPerTrayScreenMain = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setEditSteelPerTrayData({ trayId: "" });
    dispatch(clearEditData());
  };

  const { steelPerTrayListData } = useSelector(
    (state: any) => state.steelPerTrayState
  );

  const { steelListData } = useSelector((state: any) => state.steelState);
  const { trays } = useSelector((state: any) => state.trayTypeState);

  const { isMobileView, userInfo, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [searchText, setSearchText] = useState("");

  const [editSteelPerTrayData, setEditSteelPerTrayData] = useState({
    trayId: "",
  });
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [steelPerTrayFiltersData, setSteelPerTrayFiltersData] = useState({
    grade: "",
    thickness: "",
    width: "",
    length: "",
    trayNames: "",
    code: "",
  });

  const [permission, setPermission] = useState(() => {
    const getPermission = checkPermission(
      masterLocation?.locationId,
      userInfo?.ownLocation?.locationId as string,
      userInfo?.jobRole?.key as string,
      "Steel per Asset"
    );
    return getPermission;
  });

  useEffect(() => {
    refreshSteelPerTray();
  }, [steelPerTrayFiltersData, pageState]);

  useEffect(() => {
    setPermission(() => {
      const getPermission = checkPermission(
        masterLocation?.locationId,
        userInfo?.ownLocation?.locationId as string,
        userInfo?.jobRole?.key as string,
        "Steel per Asset"
      );
      return getPermission;
    });
  }, [masterLocation]);

  useEffect(() => {
    dispatch(getFilterData());
    dispatch(
      getSteelList({
        page: 1,
        pageSize: 1000,
      })
    );
    dispatch(
      getTrayTypesListing({
        page: "1",
        pageSize: "1000",
      })
    );
  }, []);

  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const onEdit = (id: any, row: any) => {
    // change header
    row = Object.keys(row)
      .filter((key) => key !== "id")
      .reduce((obj, key) => {
        if (key === "steelUsed") {
          const newA = row[key].reduce((acc: any, item: any) => {
            const temp = {
              steelId: item._id,
              quantity: item.quantity,
              code: item.code,
              width: item.width,
              length: item.length,
            };
            acc.push(temp);
            return acc;
          }, []);
          // @ts-ignore
          obj[key] = newA;
        } else {
          // @ts-ignore
          obj[key] = row[key];
        }
        return obj;
      }, {});
    setEditSteelPerTrayData(row);

    dispatch(getEditSteelPerTray(id)).then(() => {
      setAddOpen(true);
    });
  };

  const refreshSteelPerTray = () => {
    const { grade, thickness, width, length, trayNames, code } =
      steelPerTrayFiltersData;
    const searchData = {
      width: width,
      length: length,
      thickness: thickness,
      grade: grade,
      trayNames: trayNames,
      page: pageState.page,
      pageSize: pageState.pageSize,
      code: code,
    };
    dispatch(getSteelPerTrayList(searchData));
    dispatch(
      getSteelList({
        page: 1,
        pageSize: 1000,
      })
    );
    dispatch(getFilterData());
  };

  const compose = (steelPerTrayList: any) => {
    return steelPerTrayList?.map((row: any) => {
      const a = row?.steelUsed
        ?.reduce((acc: any, item: any) => {
          acc += `${item?.code ?? ""}, `;
          return acc;
        }, "")
        .slice(0, -2);
      return {
        ...row,
        code: a,
      };
    });
  };

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Steel Per Asset"
            addBtnTitle="Add Steel Per Asset"
            disabled={!permission}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
            border: "1px solid #e0e0e07a",
          }}
        >
          <div
            style={{
              padding: "16px",
              height: "calc(100% - 16px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SteelPerTrayFilters
              searchText={searchText}
              setSearchText={setSearchText}
              componentName={"STEELPERTRAY"}
              steelPerTrayFiltersData={steelPerTrayFiltersData}
              setSteelPerTrayFiltersData={setSteelPerTrayFiltersData}
              trays={trays}
            />
            <SteelPerTrayTable
              steelPerTrayListData={compose(steelPerTrayListData?.rows)}
              traysListData={trays}
              refreshSteelPerTray={refreshSteelPerTray}
              onEdit={onEdit}
              permission={permission}
            />
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={steelPerTrayListData?.total}
              page={pageState.page - 1}
              sx={{
                marginTop: "auto",
                marginBottom: "16px",
                borderTop: "1px solid rgba(224, 224, 224, 1)",
                minHeight: "52px",
              }}
              onPageChange={(
                event: React.MouseEvent<HTMLButtonElement> | null,
                newPage: number
              ) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              rowsPerPage={pageState.pageSize}
            />
          </div>
        </div>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverOutlinedIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddSteelPerTray
        open={addOpen}
        handleClose={handleAddClose}
        editData={editSteelPerTrayData}
        title={
          editSteelPerTrayData?.trayId
            ? "Edit steel per asset"
            : "Add steel per asset"
        }
        refreshSteelPerTray={refreshSteelPerTray}
        steelListData={steelListData?.rows}
        traysListData={trays}
      />
    </Box>
  );
};
