export const checkPermission = (
  filterLocation: string,
  ownLocation: string,
  jobRole: string,
  page: string
) => {
  if (jobRole === "viewer") return false;
  else if (jobRole === "super_user") return true;
  else if (jobRole === "planning_manager" && page === "Schedule")
    return ownLocation === filterLocation;
  else if (
    jobRole === "planning_manager" &&
    (page === "Assets" || page === "Steel per Asset")
  ) {
    return true;
  } else if (
    jobRole === "supply_chain_manager" &&
    (page === "Steel on Order" || page === "Inventory")
  ) {
    return ownLocation === filterLocation;
  } else if (jobRole === "supply_chain_manager" && page === "Steel") {
    return true;
  }
  return false;
};
