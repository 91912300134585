import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const colorsMap = [
  "0,0,0",
  "255,0,255",
  "0,255,0",
  "0,0,255",
  "203,190,181",
  "128,0,128",
  "0,128,128",
  "0,0,128",
  "128,0,128",
  "139,0,0",
];

export const options = {
  responsive: true,
  parsing: {
    yAxisKey: "value",
  },
  plugins: {
    legend: {
      position: "top" as const,
      // display: false,
    },
    title: {
      display: true,
      text: "Chart View of Forecast Demand based on steels available per page and filters",
      font: {
        size: 20,
      },
    },
  },
};

export const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getCurrentMonthIndex = () => {
  const currentDate = new Date();
  return currentDate.getMonth();
};

const generateMonthArray = () => {
  const currentMonthIndex = getCurrentMonthIndex();
  const monthArray = [];

  for (let i = 0; i < 12; i++) {
    const index = (currentMonthIndex + i) % 12;
    monthArray.push(labels[index]);
  }

  return monthArray;
};
const newMonthLabels = generateMonthArray();

export function ShortageChart() {
  const { shortageListData } = useSelector((state: any) => state.shortageState);
  const convertData = (obj: any) => {
    return newMonthLabels.map((e: any) => ({
      x: e,
      value: obj[e] ? obj[e] : 0,
    }));
    // return obj[e] ? obj[e] : 0;
  };
  const data = {
    labels: newMonthLabels,
    datasets:
      shortageListData?.shortageArray?.length > 0
        ? shortageListData?.shortageArray.map((e: any, index: number) => {
            let fakeIndex = index;
            if (index >= 10) fakeIndex = index - 10;

            const color = colorsMap[fakeIndex]?.split(",");
            const [firstC, secondC, thirdC] = [color[0], color[1], color[2]];

            const temp = {
              label: e.code,
              data: convertData(e),
              pointBorderColor: "black",
              pointBackgroundColor: "black",
              pointBorderWidth: 2,
              borderWidth: 2,
              segment: {
                borderColor: (ctx: any) => {
                  const point0 = ctx?.p0?.raw?.value;
                  const point1 = ctx?.p1?.raw?.value;
                  if (point1 < 0) {
                    return "rgb(255,0,0)";
                  } else if (point1 === 0) {
                    return "rgb(255,140,0)";
                  } else if (point1 < e.minQty) {
                    return "rgb(244,196,48)";
                  } else {
                    return "rgb(0,0,0)";
                  }
                },
                backgroundColor: (ctx: any) => {
                  const point0 = ctx?.p0?.raw?.value;
                  const point1 = ctx?.p1?.raw?.value;
                  if (point1 < 0) {
                    return "rgb(255,0,0)";
                  } else if (point1 === 0) {
                    return "rgb(255,140,0)";
                  } else if (point1 < e.minQty) {
                    return "rgb(244,196,48)";
                  } else {
                    return "rgb(0,0,0)";
                  }
                },
              },
            };
            return temp;
          })
        : [],
  };

  return (
    <Line
      options={options}
      data={data}
      style={{
        margin: "0 auto",
        height: "calc(100% - 150px)",
      }}
    />
  );
}
