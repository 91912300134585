import { createSlice } from "@reduxjs/toolkit";
import {
  getEditUser,
  getFilterData,
  getUserList,
} from "../../../api/admin/Api.service";

interface AdminState {
  userListData: any;
  userEditData: any;
  filterData: any;
}

const initialState: AdminState = {
  userListData: {},
  userEditData: {},
  filterData: {},
};
const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    clearEditData: (state) => {
      state.userEditData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.userListData = payload;
    });
    builder.addCase(getEditUser.fulfilled, (state, { payload }) => {
      state.userEditData = payload;
    });
    builder.addCase(getFilterData.fulfilled, (state, { payload }) => {
      state.filterData = payload;
    });
  },
});

export const AdminSliceReducer = AdminSlice.reducer;
export const { clearEditData } = AdminSlice.actions;
