import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import { usePopover } from "../../hooks";
import { PopoverComponent } from "./popover";
//@ts-ignore
import { useNavigate } from "react-router-dom";
//@ts-ignore
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//@ts-ignore
import AustinLogo from "../../assets/austinLogo.png";
import { PopoverMenuComponent } from "./popovermenu";
import theme from "../../Themes";
import titleCase from "../../utils/titleCase";
const TOP_NAV_HEIGHT = 64;

const Navbar = (props: any) => {
  const state = useSelector((state): any => state);
  const navigate = useNavigate();
  const accountPopover = usePopover();
  const menupopover = usePopover();
  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
          boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.toggleDrawer}
            >
              <MenuIcon style={{ color: theme.palette.indicator.main }} />
            </IconButton>
            <img
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
              src={AustinLogo}
              alt="austin logo"
              className="fam-doc-logo"
            />
          </div>
          <Stack
            alignItems="center"
            direction="row"
            spacing={1.2}
            onClick={accountPopover.handleOpen}
            ref={accountPopover.anchorRef}
            sx={{
              cursor: "pointer",
            }}
          >
            <Avatar
              sx={{
                height: 35,
                width: 35,
                bgcolor: theme.palette.indicator.main,
              }}
            >
              {titleCase(state.globalAppState?.userInfo?.fullName[0])}
            </Avatar>
            <Typography
              variant="subtitle1"
              color={theme.palette.text.secondary}
            >
              {titleCase(
                state.globalAppState?.userInfo?.fullName?.split(" ")[0]
              )}
            </Typography>
            <ExpandMoreIcon sx={{ marginLeft: "0 !important" }} />
          </Stack>
        </Stack>
      </Box>
      <PopoverMenuComponent
        anchorEl={menupopover.anchorRef.current}
        open={menupopover.open}
        onClose={menupopover.handleClose}
      />
      <PopoverComponent
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};

export default Navbar;
