import { createSlice } from "@reduxjs/toolkit";
import {
  getSteelOnOrderList,
  getEditSteelOnOrder,
  getFilterData,
} from "../../../api/steelonorder/Api.service";

interface SteelOnOrderState {
  steelOnOrderListData: any;
  steelOnOrderEditData: any;
  filterData: any;
}

const initialState: SteelOnOrderState = {
  steelOnOrderListData: {},
  steelOnOrderEditData: {},
  filterData: {},
};

const SteelOnOrderSlice = createSlice({
  name: "steelonorder",
  initialState,
  reducers: {
    clearEditData: (state) => {
      state.steelOnOrderEditData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSteelOnOrderList.fulfilled, (state, { payload }) => {
      state.steelOnOrderListData = payload;
    });
    builder.addCase(getEditSteelOnOrder.fulfilled, (state, { payload }) => {
      state.steelOnOrderEditData = payload;
    });
    builder.addCase(getFilterData.fulfilled, (state, { payload }) => {
      state.filterData = payload;
    });
  },
});

export const SteelOnOrderSliceReducer = SteelOnOrderSlice.reducer;
export const { clearEditData } = SteelOnOrderSlice.actions;
