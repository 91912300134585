import { Outlet } from "react-router-dom";
import Navbar from "../../component/navbar/Navbar";
import Sidebar from "../../component/sidebar/Sidebar";
import "./style.css";
import React from "react";

const Home = () => {
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className="home-container">
      <div className="home-hero-container-top">
        <Navbar toggleDrawer={toggleDrawer} open={open} />
      </div>
      <div className="home-hero-container-bottom">
        <div className="home-hero-container-bottom-left">
          <Sidebar open={open} setOpen={setOpen} />
        </div>
        <div className="home-hero-container-bottom-right">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
