import { createAsyncThunk } from "@reduxjs/toolkit";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import { GET_ALL_TOTAL } from "../ActionConstants";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
} from "../Api.service";
import API from "../Api.utils";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { reformatDates } from "../../utils/reformatDates";

export const getTotalList = createAsyncThunk(
  GET_ALL_TOTAL,
  async (postData: any, thunkApi) => {
    let {
      name,
      code,
      probability,
      purchaseEndDate,
      processingEndDate,
      processingStartDate,
      purchaseStartDate,
      page,
      pageSize,
      location,
      grade,
    } = postData;

    purchaseStartDate = reformatDates(purchaseStartDate);
    purchaseEndDate = reformatDates(purchaseEndDate);
    processingStartDate = reformatDates(processingStartDate);
    processingEndDate = reformatDates(processingEndDate);

    const { rejectWithValue, signal, dispatch } = thunkApi;

    try {
      const codeUrl = generateCodeUrl(code);
      const url = `totalRequirement?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${codeUrl}${name ? `name=${name}&` : ""}${
        grade ? `grade=${grade}&` : ""
      }${probability ? `probability=${probability}&` : ""}${
        purchaseEndDate ? `purchaseEndDate=${purchaseEndDate}&` : ""
      }${processingEndDate ? `processingEndDate=${processingEndDate}&` : ""}${
        processingStartDate ? `processingStartDate=${processingStartDate}&` : ""
      }${purchaseStartDate ? `purchaseStartDate=${purchaseStartDate}&` : ""}${
        location !== "all" ? `locationId=${location}&` : ""
      }`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };

      const newSteelReqData = finelObj?.steelReqData.map((obj: any) => ({
        ...obj,
        // name: `${obj.name}, ${obj.length}mm, ${obj.width}mm`,
      }));

      const obj = {
        code: "Total Assets",
        isClickEnable: true,
        ...finelObj?.trayData,
      };

      const finelArr = {
        ...finelObj,
        steelReqData: [obj, ...newSteelReqData],
      };
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );
      return finelArr;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Total Data ...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
