import { createSlice } from "@reduxjs/toolkit";
import {
  getSteelPerTrayList,
  getEditSteelPerTray,
  getFilterData,
} from "../../../api/steelpertray/Api.service";

interface SteelPerTrayState {
  steelPerTrayListData: any;
  steelPerTrayEditData: any;
  filterData: any;
}

const initialState: SteelPerTrayState = {
  steelPerTrayListData: {},
  steelPerTrayEditData: {},
  filterData: {},
};

const SteelPerTraySlice = createSlice({
  name: "steelpertray",
  initialState,
  reducers: {
    clearEditData: (state) => {
      state.steelPerTrayEditData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSteelPerTrayList.fulfilled, (state, { payload }) => {
      state.steelPerTrayListData = payload;
    });
    builder.addCase(getEditSteelPerTray.fulfilled, (state, { payload }) => {
      state.steelPerTrayEditData = payload;
    });
    builder.addCase(getFilterData.fulfilled, (state, { payload }) => {
      state.filterData = payload;
    });
  },
});

export const SteelPerTraySliceReducer = SteelPerTraySlice.reducer;
export const { clearEditData } = SteelPerTraySlice.actions;
