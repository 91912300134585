import Accordion from "@mui/material/Accordion";
import { Tooltip } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Field, Formik, Form } from "formik";
import { Box, Button, Grid, MenuItem } from "@mui/material";
import { TextField } from "formik-mui";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as yup from "yup";
import { initialTempShortageData } from "./data";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { addShortage, deleteTemopOrder } from "../../api/shortage/Api.service";
import dayjs from "dayjs";
import { dateFormater } from "../schedule/AddScheduleOrder";
import { clearTemp } from "./slice/SteelSlice";
// import moment from "moment";

export const TemporaryOrderTray = (props: any) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { id, setId } = props;
  const dispatch: AppDispatch = useDispatch();
  const { tempOrderData } = useSelector(
    (state: RootState) => state.shortageState
  );
  const { filterData: shortageData } = useSelector(
    (state: RootState) => state.shortageState
  );
  const { masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleClose = () => {
    setExpanded(false);
  };

  const cssStyles = {
    fontSize: "0.95rem",
    marginTop: "10px",
    textAlign: "center",
  };

  const handleReset = () => {
    dispatch(deleteTemopOrder(tempOrderData?._id)).then((e: any) => {
      dispatch(clearTemp());
      setId("");
      setExpanded(false);
      props.runTempOrder();
    });
  };
  const now = dayjs();
  return (
    <div>
      <Accordion
        disableGutters
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        TransitionProps={{ unmountOnExit: true }}
        elevation={1}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "0.95rem" }}>
            Temporary Order for Asset
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={
              id
                ? tempOrderData
                  ? tempOrderData
                  : initialTempShortageData
                : initialTempShortageData
            }
            // validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              const tempData = {
                ...values,
                locationId: masterLocation.locationId,
                userId: 1,
              };
              delete tempData.trayType;
              delete tempData.id;
              delete tempData._id;
              delete tempData.processingDate;

              dispatch(addShortage(tempData)).then(() => {
                setId("1");
                props.runTempOrder();
              });
            }}
          >
            {({
              values,
              submitForm,
              resetForm,
              isSubmitting,
              touched,
              errors,
              setFieldValue,
            }) => (
              <>
                <Box
                  ml={0}
                  sx={{
                    p: 3,
                    backgroundColor: "#fff ",
                  }}
                >
                  <Form>
                    <Grid container spacing={2.8}>
                      <Grid item xs={3} sm={3} md={3}>
                        <Typography sx={cssStyles}>Asset Name</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <Field
                          component={TextField}
                          type="text"
                          name="trayId"
                          id="trayType"
                          label="Asset Name"
                          select
                          variant="outlined"
                          required
                          size="small"
                          style={{ width: "233px" }}
                        >
                          {props?.trays?.map((option: any, index: number) => (
                            <MenuItem key={index} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={3} sm={3} md={3}>
                        <Typography sx={cssStyles}>Customer Name</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <Field
                          component={TextField}
                          id="customerName"
                          name="customerName"
                          required
                          size="small"
                          label="Customer Name"
                          variant="outlined"
                          style={{ width: "200px" }}
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <Typography sx={cssStyles}>Purchase Date</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Field
                            component={DatePicker}
                            id="date"
                            name="purchaseDate"
                            required
                            size="small"
                            label="Purchase Date"
                            fullWidth
                            variant="outlined"
                            className="date_picker"
                            // disableFuture
                            format="DD-MM-YYYY"
                            defaultValue={
                              values.purchaseDate
                                ? dayjs(values.purchaseDate)
                                : null
                            }
                            onChange={(date: any) => {
                              setFieldValue(
                                "purchaseDate",
                                dateFormater(date?.$d, "-")
                              );
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                required: true,
                                width: "200px",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3} sm={3} md={3}>
                        <Typography sx={cssStyles}>Quantity</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <Field
                          component={TextField}
                          id="quantity"
                          name="quantity"
                          size="small"
                          label="Quantity"
                          variant="outlined"
                          required
                          style={{ width: "200px" }}
                        />
                      </Grid>

                      <Grid item xs={3} sm={3} md={3}>
                        <Typography
                          sx={{
                            fontSize: "0.95rem",
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          Delivery Date
                          <Tooltip title="SLS RTS EOL Plan">
                            <InfoOutlinedIcon
                              color="info"
                              sx={{ px: "5px", cursor: "pointer" }}
                            ></InfoOutlinedIcon>
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Field
                            component={DatePicker}
                            id="date"
                            name="deliveryDate"
                            size="small"
                            label="Delivery Date"
                            fullWidth
                            variant="outlined"
                            className="date_picker"
                            minDate={
                              dayjs(
                                values?.purchaseDate
                                  ? values?.purchaseDate
                                      .split("-")
                                      .reverse()
                                      .join("-")
                                  : null
                              )
                              // && now.month(now.month() + 1).date(1)
                            }
                            // disableFuture
                            format="DD-MM-YYYY"
                            defaultValue={
                              values.deliveryDate
                                ? dayjs(values.deliveryDate)
                                : null
                            }
                            onChange={(date: any) => {
                              setFieldValue(
                                "deliveryDate",
                                dateFormater(date?.$d, "-")
                              );
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                required: true,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3} sm={3} md={3}>
                        <Typography sx={cssStyles}>Probability %</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3}>
                        <Field
                          component={TextField}
                          id="probability"
                          name="probability"
                          size="small"
                          label="Probability"
                          variant="outlined"
                          required
                          style={{ width: "200px" }}
                        />
                      </Grid>
                    </Grid>

                    <Box
                      className="popupBtn"
                      display={"flex"}
                      justifyContent={"center"}
                      gap={2}
                      pt={4}
                    >
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Create Order
                      </Button>

                      <Button
                        onClick={() => handleReset()}
                        variant="contained"
                        color="error"
                      >
                        Delete Order
                      </Button>
                      <Button onClick={handleClose} variant="outlined">
                        Cancel
                      </Button>
                    </Box>
                  </Form>
                </Box>
              </>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
