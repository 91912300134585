import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Grid,
  Button,
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TextField } from "formik-mui";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import ReportIcon from "@mui/icons-material/Report";
import "./style.css";
import { useLocation } from "react-router-dom";
import { resetPassword, createPassword } from "../../api/Api.service";

var ImgWithDoc = require("../../assets/famdocwithdoc.png");
var ImgWithoutDoc = require("../../assets/austinLogo.png");

interface Values {
  token?: string;
  password: string;
  confirmPassword: string;
}

export interface ResetPasswordPayload {
  data: Values;
  callback: Function;
}

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Please enter your new password")
    .min(8, "New password must be between 8-12 characters")
    .max(12, "New password must be between 8-12 characters")
    .matches(/[a-z]+/, "Atleast one lowercase character is required")
    // .matches(/[A-Z]+/, "One Uppercase Character Is Required")
    .matches(
      /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/,
      "Atleast one special character is required"
    )
    .matches(/\d+/, "Atleast one numeric character is required"),
  confirmPassword: yup
    .string()
    .required("Please confirm your new password")
    .oneOf(
      [yup.ref("password")],
      "Confirm password should be same as new password"
    ),
});

const ResetPwd = () => {
  let { token } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const payloadVerify = {
      data: pathname.split("/")[2],
    };
    // dispatch(verifyResetPasswordLink(payloadVerify));
  }, []);
  const { verifyResetLinkFailed } = useSelector(
    (state: RootState) => state.globalAppState
  );
  return (
    <>
      <div className="login-container">
        {!isMobileView && (
          <div className="left-login-container">
            <img src={ImgWithDoc} alt="" className="left-login-container-img" />
          </div>
        )}

        <div className="right-login-container">
          <img
            width={"200px"}
            src={ImgWithoutDoc}
            alt=""
            className="right-login-container-img"
          />

          {verifyResetLinkFailed ? (
            <div className="login-form-container">
              <div className="icon-container">
                <ReportIcon style={{ fontSize: "80px", color: "brown" }} />
              </div>
              <div
                className="warning-container-text"
                style={{ fontSize: isMobileView ? "22px" : "30px" }}
              >
                Sorry, Your Link Has Expired!
              </div>

              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Button
                  onClick={() => {
                    navigate("/");
                  }}
                  variant="contained"
                >
                  Back to Login
                </Button>
              </Grid>
            </div>
          ) : (
            <div className="login-form-container">
              <div className="heading-reset-pwd-container">
                <Box component="main">
                  <Stack spacing={3}>
                    <Stack direction="row" justifyContent="center" spacing={4}>
                      <Stack spacing={1}>
                        <Typography variant="h5">
                          {pathname.includes("reset") ? "Reset" : "Create"}{" "}
                          Password
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </div>
              <div style={{ padding: 30 }}>
                <Formik
                  initialValues={{
                    token,
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    const navigateback = () => {
                      return navigate(`/`);
                    };
                    const payloadData = {
                      data: values,
                      callback: navigateback,
                    };
                    pathname.includes("reset")
                      ? dispatch(resetPassword(payloadData))
                      : dispatch(createPassword(payloadData));
                  }}
                >
                  {({
                    values,
                    submitForm,
                    resetForm,
                    isSubmitting,
                    touched,
                    errors,
                  }) => (
                    <>
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              fullWidth
                              name="password"
                              id="password"
                              label="Enter your New Password"
                              type={"password"}
                              variant="outlined"
                              required
                              size="small"
                            ></Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              fullWidth
                              name="confirmPassword"
                              id="confirmPassword"
                              label="Confirm your New Password"
                              type={"password"}
                              variant="outlined"
                              required
                              size="small"
                            ></Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              onClick={submitForm}
                              fullWidth
                              variant="contained"
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPwd;
