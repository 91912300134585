// form
export const SteelPerTrayData = [
  { id: "1", trayName: "HPT 830 HD" },
  { id: "2", trayName: "Ultima 798" },
  { id: "3", trayName: "Ultima 793" },
  { id: "4", trayName: "Ultima 790" },
  { id: "5", trayName: "HPT 830 Max std Duty - RTIO" },
  { id: "6", trayName: "HPT 930 Max std Duty - RTIO" },
];

// form
export const initialSteelPerTrayData = {
  trayId: "",
  steelUsed: [
    {
      steelId: "",
      code: "",
      length: "",
      width: "",
      quantity: "",
    },
  ],
};

// form
export const SteelGradeData = [
  "Grade BHN450 6mm",
  "Grade A514 6mm",
  "Grade BHN450 8mm",
  "Grade BHN450 8mm",
  "Grade BHN450 10mm",
  "Grade BHN450 10mm",
];
