import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_STEEL_PER_TRAY,
  ADD_STEEL_PER_TRAY,
  DELETE_STEEL_PER_TRAY,
  EDIT_STEEL_PER_TRAY,
  GET_EDIT_STEEL_PER_TRAY,
  GET_DROPDOWN_STEEL_PER_TRAY,
} from "../ActionConstants";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";

export const getSteelPerTrayList = createAsyncThunk(
  GET_STEEL_PER_TRAY,
  async (postData: any, thunkApi) => {
    const { grade, thickness, width, length, page, pageSize, trayNames, code } =
      postData;
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steelPerTray?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${trayNames ? `trayName=${trayNames}&` : ""}${
        grade ? `grade=${grade}&` : ""
      }${thickness ? `thickness=${thickness}&` : ""}${
        width ? `width=${width}&` : ""
      }${length ? `length=${length}&` : ""}${code ? `code=${code}&` : ""}`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );
      return finelObj;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching steel per trays!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const addSteelPerTray = createAsyncThunk(
  ADD_STEEL_PER_TRAY,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post("steelPerTray/add", postData.data, {
        signal,
      });
      if (response) {
        postData?.callback();
        const data = response?.data?.result;
        if (response.data.status === 0) {
          dispatch(toggleSnacker(errorOpen(response.data.message)));
        } else {
          dispatch(
            toggleSnacker(
              successOpen("Steel per asset has been added successfully!")
            )
          );
        }
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while adding new steel per asset!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteSteelPerTray = createAsyncThunk(
  DELETE_STEEL_PER_TRAY,
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.delete(
        `steelPerTray?trayId=${postData?.trayId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response?.data;
      if (data.status === 0) {
        dispatch(toggleSnacker(errorOpen(data.message)));
      } else {
        dispatch(
          toggleSnacker(
            successOpen(
              data?.responseMessage
                ? data?.responseMessage
                : "Steel per asset has been deleted successfully!"
            )
          )
        );
      }
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while deleting the steel per order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const editSteelPerTray = createAsyncThunk(
  EDIT_STEEL_PER_TRAY,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.patch("steelPerTray", postData?.data, {
        signal,
      });
      if (response) {
        postData?.callback();
        const data = response?.data?.result;
        if (response?.data?.status === 0) {
          dispatch(toggleSnacker(errorOpen(response?.data?.message)));
        } else {
          dispatch(
            toggleSnacker(
              successOpen("Steel per asset has been successfully updated!")
            )
          );
        }
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while updating the steel per asset!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getEditSteelPerTray = createAsyncThunk(
  GET_EDIT_STEEL_PER_TRAY,
  async (id: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(`steelPerTray/?trayId=${id}`, { signal });
      if (response) {
        const data = response?.data?.data;
        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching the steel per asset!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getFilterData = createAsyncThunk(
  GET_DROPDOWN_STEEL_PER_TRAY,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steelPerTray/dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, { signal });
      const data = response?.data?.data;
      const temp = {
        ...data,
        trayNames: data.names,
      };
      return temp;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching steel per trays!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getBrandSteelPerTray = createAsyncThunk(
  "GET_BRAND_STEEL_PER_TRAY",
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post("steelPerTray/brandList", postData.data, {
        signal,
      });
      if (response) {
        postData?.callback();
        const data = response?.data?.result;
        // dispatch(
        //   toggleSnacker(successOpen("Steel Per Asset Added Successfully!"))
        // );
        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Saving Steel, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
