import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../sidebar/Sidebar";
import { PopoverMenuComponentProps } from "./types";

export const PopoverMenuComponent = (
  props: PopoverMenuComponentProps
): JSX.Element => {
  const navigate = useNavigate();
  const { anchorEl, onClose, open } = props;
  const [openMaster, setOpenMaster] = React.useState(false);
  const [openPurchaseOrders, setOpenPurchaseOrders] = React.useState(true);
  const location = useLocation();
  const { pathname } = location;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 260 } }}
    >
      <MenuList
        disablePadding
        dense
        sx={{
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => setOpenMaster(!openMaster)}
              className="sidebar-container-items"
            >
              <span>
                <HomeIcon />
              </span>
              <span>Master</span>
              {openMaster ? (
                <ExpandLessOutlinedIcon />
              ) : (
                <ExpandMoreOutlinedIcon />
              )}
            </div>
            <Collapse in={openMaster} timeout="auto">
              <List>
                <ListItemButton
                  onClick={() => {
                    navigate("./steel");
                  }}
                  className={
                    pathname.split("/").includes("steel") || pathname === "/"
                      ? "sidebar-container-items active-sidebar"
                      : "sidebar-container-items"
                  }
                >
                  <span>Steel</span>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate("./asset");
                  }}
                  className={
                    pathname.split("/").includes("asset") || pathname === "/"
                      ? "sidebar-container-items active-sidebar"
                      : "sidebar-container-items"
                  }
                >
                  <span>Asset</span>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate("./steelPerAsset");
                  }}
                  className={
                    pathname.split("/").includes("steel-per-asset") ||
                    pathname === "/"
                      ? "sidebar-container-items active-sidebar"
                      : "sidebar-container-items"
                  }
                >
                  <span>Steel Per Asset</span>
                </ListItemButton>
              </List>
            </Collapse>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => setOpenPurchaseOrders(!openPurchaseOrders)}
              className="sidebar-container-items"
            >
              <span>
                <ShoppingBagIcon />
              </span>
              <span>Purchase Orders</span>
              {openPurchaseOrders ? (
                <ExpandLessOutlinedIcon />
              ) : (
                <ExpandMoreOutlinedIcon />
              )}
            </div>
            <Collapse in={openPurchaseOrders} timeout="auto">
              <List>
                <ListItemButton
                  onClick={() => {
                    navigate("./received");
                  }}
                  className={
                    pathname.split("/").includes("received") || pathname === "/"
                      ? "sidebar-container-items active-sidebar"
                      : "sidebar-container-items"
                  }
                  sx={{
                    marginLeft: "-40px",
                  }}
                >
                  <span>Received</span>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate("./raised");
                  }}
                  className={
                    pathname.split("/").includes("raised") || pathname === "/"
                      ? "sidebar-container-items active-sidebar"
                      : "sidebar-container-items"
                  }
                  sx={{
                    marginLeft: "-40px",
                  }}
                >
                  <span>Raised</span>
                </ListItemButton>
              </List>
            </Collapse>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem>
          <div
            onClick={() => {
              navigate("./total-requirement");
            }}
            className={
              pathname.split("/").includes("total-requirement")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <span>
              <RequestQuoteIcon />
            </span>
            <span>Total Requirement</span>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem>
          <div
            onClick={() => {
              navigate("./inventory");
            }}
            className={
              pathname.split("/").includes("inventory")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <InventoryIcon />

            <span>Inventory</span>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem>
          <div
            onClick={() => {
              navigate("./shortage");
            }}
            className={
              pathname.split("/").includes("shortage")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <span>
              <AssignmentLateIcon />
            </span>
            <span>Shortage</span>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem>
          <div
            onClick={() => {
              navigate("./reports");
            }}
            className={
              pathname.split("/").includes("reports")
                ? "sidebar-container-items active-sidebar"
                : "sidebar-container-items"
            }
          >
            <span>
              <AssessmentIcon />
            </span>
            <span>Reports</span>
          </div>
        </MenuItem>
      </MenuList>
    </Popover>
  );
};
