import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart View of Total Requirement based on steels available per page and filters",
      font: {
        size: 20,
      },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const colorsMap = [
  "0,0,0",
  "255,0,0",
  "0,255,0",
  "0,0,255",
  "255,255,0",
  "128,0,128",
  "0,128,128",
  "0,0,128",
  "128,128,128",
  "255,69,0",
];

const getCurrentMonthIndex = () => {
  const currentDate = new Date();
  return currentDate.getMonth();
};

const generateMonthArray = () => {
  const currentMonthIndex = getCurrentMonthIndex();
  const monthArray = [];

  for (let i = 0; i < 12; i++) {
    const index = (currentMonthIndex + i - 1) % 12;
    monthArray.push(labels[index]);
  }

  return monthArray;
};
const newMonthLabels = generateMonthArray();

export function TotalRequirementChart() {
  const { totalRListData } = useSelector((state: any) => state.TotalRState);

  const convertData = (obj: any) => {
    return newMonthLabels.map((e: any) => {
      return obj[e?.toLowerCase()] ? obj[e?.toLowerCase()] : 0;
    });
  };

  const data = {
    labels: newMonthLabels,
    datasets:
      totalRListData?.steelReqData?.length > 0
        ? totalRListData?.steelReqData
            ?.slice(0, 11)
            ?.filter((e: any, i: any) => i !== 0)
            .map((e: any, index: number) => {
              let fakeIndex = index;
              if (index >= 10) fakeIndex = index - 10;

              const color = colorsMap[fakeIndex]?.split(",");
              if (color) {
                const [firstC, secondC, thirdC] = [
                  color[0],
                  color[1],
                  color[2],
                ];

                return {
                  label: e.code,
                  data: convertData(e),
                  borderColor: `rgb(${firstC}, ${secondC}, ${thirdC})`,
                  backgroundColor: `rgb(${firstC}, ${secondC}, ${thirdC})`,
                  pointBackgroundColor: "black",
                  pointBorderColor: "black",
                  pointBorderWidth: 2,
                  borderWidth: 2,
                };
              }
            })
        : [],
  };

  return (
    <Line
      options={options}
      data={data}
      style={{
        margin: "0 auto",
        height: "calc(100% - 150px)",
      }}
    />
  );
}
