import { createSlice } from "@reduxjs/toolkit";
import {
  deleteTemopOrder,
  getChartShortageList,
  getEditTempOrder,
  getFilterDataShortage,
  getShortageList,
  getReplacementData,
} from "../../../api/shortage/Api.service";

interface ShortageState {
  shortageListData: any;
  shortageChartData: any;
  filterData: any;
  tempOrderData: any;
  replacementData: any;
}

const initialState: ShortageState = {
  shortageListData: {},
  shortageChartData: {},
  filterData: {},
  tempOrderData: {},
  replacementData: {},
};
const ShortageSlice = createSlice({
  name: "shortage",
  initialState,
  reducers: {
    clearEditData: (state) => {
      state.shortageChartData = {};
    },
    clearTemp: (state) => {
      state.tempOrderData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShortageList.fulfilled, (state, { payload }) => {
      state.shortageListData = payload;
    });
    builder.addCase(getChartShortageList.fulfilled, (state, { payload }) => {
      state.shortageChartData = payload;
    });
    builder.addCase(getFilterDataShortage.fulfilled, (state, { payload }) => {
      state.filterData = payload;
    });
    builder.addCase(getEditTempOrder.fulfilled, (state, { payload }) => {
      state.tempOrderData = payload;
    });
    builder.addCase(deleteTemopOrder.fulfilled, (state, { payload }) => {
      state.tempOrderData = {};
    });
    builder.addCase(getReplacementData.fulfilled, (state, { payload }) => {
      state.replacementData = payload;
    });
  },
});

export const ShortageSliceReducer = ShortageSlice.reducer;
export const { clearEditData, clearTemp } = ShortageSlice.actions;
