import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { reformatDates } from "../../utils/reformatDates";

export const getSteelOnOrderList = createAsyncThunk(
  "GET_STEEL_ON_ORDER_LIST",
  async (postData: any, thunkApi) => {
    let {
      orderNumber,
      supplier,
      status,
      steelName,
      code,
      brand,
      deliveryStartDate,
      deliveryEndDate,
      page,
      pageSize,
      location,
    } = postData;

    deliveryStartDate = reformatDates(deliveryStartDate);
    deliveryEndDate = reformatDates(deliveryEndDate);

    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const codeUrl = generateCodeUrl(code);
      const url = `steelPoRaised?${page ? `page=${page}&` : ""}${
        pageSize ? `pageSize=${pageSize}&` : ""
      }${codeUrl}${orderNumber ? `orderNumber=${orderNumber}&` : ""}${
        status ? `status=${status}&` : ""
      }${brand ? `brand=${brand}&` : ""}${
        steelName ? `name=${steelName}&` : ""
      }${supplier ? `supplier=${supplier}&` : ""}${
        deliveryStartDate ? `deliveryStartDate=${deliveryStartDate}&` : ""
      }${deliveryEndDate ? `deliveryEndDate=${deliveryEndDate}&` : ""}${
        location !== "all" ? `locationId=${location}&` : ""
      }`;
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(url, { signal });
      const data = response?.data?.data;
      const finelObj = {
        ...data,
        rows: data?.rows?.map((item: any) => ({
          ...item,
          id: item._id,
        })),
      };

      const tempData = JSON.parse(JSON.stringify(finelObj));
      const moreTemp = tempData.orderRows.map((temp: any) => {
        temp.purchaseOrderNo = temp.orderNumber;
        const a = temp.steelUsed.map((steelT: any) => ({
          ...steelT,
          deliveryDate: reformatDates(steelT?.deliveryDate),
          purchaseDate: reformatDates(steelT?.purchaseDate),
          ...(steelT?.forecastedDate && {
            forecastedDate: steelT?.forecastedDate
              .split("-")
              .reverse()
              .join("-"),
          }),
        }));
        temp.steelUsed = a;
        delete temp.orderNumber;
        return temp;
      });
      const tempo = {
        ...tempData,
        orderRows: moreTemp,
      };
      return tempo;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching orders!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const addSteelOnOrder = createAsyncThunk(
  "ADD_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const tempPostData = JSON.parse(JSON.stringify(postData?.data));

      const tempSteelUsed = tempPostData?.steelUsed.map((steel: any) => ({
        ...steel,
        deliveryDate: reformatDates(steel?.deliveryDate),
        purchaseDate: reformatDates(steel?.purchaseDate),
        ...(steel?.forecastedDate && {
          forecastedDate: reformatDates(steel?.forecastedDate),
        }),
      }));
      tempPostData.orderNumber = tempPostData?.purchaseOrderNo;
      tempPostData.steelUsed = tempSteelUsed;

      delete tempPostData.id;
      delete tempPostData.purchaseOrderNo;

      const response = await API.post("steelPoRaised", tempPostData, {
        signal,
      });
      if (response) {
        postData?.callback();
        const data = response?.data;
        if (data?.status === 0)
          dispatch(toggleSnacker(errorOpen(data?.message)));
        else
          dispatch(
            toggleSnacker(
              successOpen("Steel on order has been created successfully!")
            )
          );
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while creating new steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteSteelOnOrder = createAsyncThunk(
  "DELETE_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      // TODO: change trayId
      const response = await API.delete(
        `steelPoRaised?steelPOId=${postData?.id}&locationId=${postData?.locationId}`,
        {
          signal,
        }
      );
      postData.callback();
      const data = response?.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Steel on order has been deleted successfully!"
          )
        )
      );
      return {};
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while deleting the steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const editSteelOnOrder = createAsyncThunk(
  "EDIT_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const tempPostData = JSON.parse(JSON.stringify(postData?.data));
      const tempSteelUsed = tempPostData?.steelUsed.map((steel: any) => ({
        steelId: steel?.steelId,
        code: steel?.code,
        brand: steel?.brand,
        quantity: steel?.quantity,
        status: steel?.status,
        deliveryDate: reformatDates(steel?.deliveryDate),
        purchaseDate: reformatDates(steel?.purchaseDate),
        ...(steel?.forecastedDate && {
          forecastedDate: reformatDates(steel?.forecastedDate),
        }),
        ...(steel?.outstandingQty && {
          outstandingQty: steel?.outstandingQty,
        }),
      }));

      tempPostData.orderNumber = tempPostData?.purchaseOrderNo;
      tempPostData.steelUsed = tempSteelUsed;

      delete tempPostData.purchaseOrderNo;

      const response = await API.patch("steelPoRaised", tempPostData, {
        signal,
      });
      if (response) {
        postData?.callback();
        const data = response?.data;
        if (data.status === 0)
          dispatch(toggleSnacker(errorOpen(data?.message)));
        else
          dispatch(
            toggleSnacker(
              successOpen("Steel on order has been successfully updated!")
            )
          );
        return {};
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while updating the steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getEditSteelOnOrder = createAsyncThunk(
  "GET_EDIT_STEEL_ON_ORDER",
  async (params: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      // TODO: change trayId
      const response = await API.get(
        `steelPoRaised/?steelPOOrderId=${params.id}&locationId=${params.locationId}`,
        {
          signal,
        }
      );
      if (response) {
        const data = response?.data?.data;
        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching the steel on order!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const getFilterData = createAsyncThunk(
  "GET_DROPDOWN_STEEL_ON_ORDER",
  async (postData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const url = `steelPoRaised/dropdown`;
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(url, postData, { signal });
      const data = response?.data?.data;
      const temp = {
        ...data,
        purchaseOrderNumber: data.orderNumber,
        steelName: data.names,
        status: ["On Order", "Back Order", "Fulfilled"],
        location: data.allowedLocations,
      };
      return temp;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error occurred while fetching steel on order!"
          )
        )
      );
      // dispatch(toggleLoader(closeLoaderPayload));
      return {};
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
