import {
  Button,
  Card,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { QuickSearchToolbarProps } from "../../component/search/types";
import theme from "../../Themes";
import titleCase from "../../utils/titleCase";
import React from "react";
import { VirtualizeName } from "../steelPerTray/Virtualize";

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { filterData } = useSelector((state: RootState) => state.adminState);
  const [value, setValue] = React.useState<string | null>("");
  const [inputValue, setInputValue] = React.useState("");

  const { setSteelData, steelData } = props;

  const updateSteelData = (value: any, name: any) => {
    setSteelData({ ...steelData, [name]: value !== "All" ? value : "" });
  };

  const clearAllFilters = () => {
    setSteelData({
      names: "",
      emailIds: "",
      isActive: "",
      roles: "",
      locations: masterLocation.locationId,
    });
    setValue(null);
    setInputValue("");
  };
  if (filterData?.locations?.length) {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pr={2}
      >
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <VirtualizeName
              options={filterData?.names?.map((s: any) => s)}
              value={value}
              setValue={setValue}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleChange={updateSteelData}
            />
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="emailIds"
              label={`Email`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.emailIds}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.emailIds?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.emailIds?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="roles"
              label={`Role`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.roles}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.roles?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.roles?.map((option: any, i: number) => (
                <MenuItem
                  // @ts-ignore
                  key={Object.values(option)[0]}
                  value={Object.keys(option)[0]}
                >
                  {titleCase(Object.values(option)[0])}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="isActive"
              label={`Status`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.isActive}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.isActive?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.isActive?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {titleCase(option)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="locations"
              label={`Location`}
              select
              variant="outlined"
              fullWidth
              value={steelData?.locations}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.locations?.length ? (
                <MenuItem value={"all"}>All</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.locations?.map((option: any, i: number) => (
                <MenuItem
                  // @ts-ignore
                  key={Object.values(option)[0]}
                  value={Object.keys(option)[0]}
                >
                  {titleCase(Object.values(option)[0])}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return <></>;
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const AdminFilters = (props: QuickSearchToolbarProps) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
