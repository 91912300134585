import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
import { AddSteel } from "./AddSteel";
import {
  deleteSteel,
  getEditSeel,
  getFilterData,
  getSteelList,
} from "../../api/steel/Api.service";
import { clearEditData } from "./slice/SteelSlice";
import { SteelFilters } from "./SteelFilters";
import { checkPermission } from "../../utils/checkPermission";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export const SteelScreenMain = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setEditSteelData({ id: "" });
    dispatch(clearEditData());
  };

  const { steelListData } = useSelector((state: any) => state.steelState);
  const { isMobileView, masterLocation, userInfo } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [editSteelData, setEditSteelData] = useState({ id: "" });
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [steelData, setSteelData] = useState({
    code: "",
    grade: "",
    thickness: "",
    width: "",
    length: "",
  });

  const [permission, setPermission] = useState(() => {
    const getPermission = checkPermission(
      masterLocation?.locationId,
      userInfo?.ownLocation?.locationId as string,
      userInfo?.jobRole?.key as string,
      "Steel"
    );
    return getPermission;
  });

  useEffect(() => {
    refreshSteel();
  }, [pageState, steelData]);

  useEffect(() => {
    dispatch(getFilterData());
  }, [steelListData]);

  useEffect(() => {
    setPermission(() => {
      const getPermission = checkPermission(
        masterLocation?.locationId,
        userInfo?.ownLocation?.locationId as string,
        userInfo?.jobRole?.key as string,
        "Steel"
      );
      return getPermission;
    });
  }, [masterLocation]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const refreshSteel = () => {
    const { grade, thickness, width, length, code } = steelData;
    const searchData = {
      page: pageState.page,
      pageSize: pageState.pageSize,
      thickness: thickness,
      width: width,
      length: length,
      grade: grade,
      code: code,
    };
    dispatch(getSteelList(searchData));
    dispatch(getFilterData());
  };

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "CODE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "PRODUCT NAME",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "grade",
      headerName: "GRADE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "thickness",
      headerName: "THICKNESS",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "width",
      headerName: "WIDTH",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "length",
      headerName: "LENGTH",
      width: 100,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "brand",
      headerName: "BRAND",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "manufacturer",
      headerName: "MANUFACTURER",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "minQty",
      headerName: "MIN. QTY.",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        const { status, is_form_filled } = params.row;
        const onClick = (e: any) => {
          const currentRow = params.row;
          setDeletId(currentRow._id);
          handleOpen();
        };
        const onEdit = (e: any) => {
          const currentRow = params.row;
          setEditSteelData(currentRow);
          dispatch(getEditSeel(currentRow._id)).then(() => {
            setAddOpen(true);
          });
        };

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="Edit">
              <IconButton disabled={!permission}>
                <EditOutlinedIcon
                  color={permission ? "error" : "disabled"}
                  onClick={onEdit}
                ></EditOutlinedIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton disabled={!permission}>
                <DeleteForeverOutlinedIcon
                  color={permission ? "error" : "disabled"}
                  onClick={onClick}
                ></DeleteForeverOutlinedIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Steel"
            addBtnTitle="Add Steel"
            disabled={!permission}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          <DataGrid
            sx={{
              padding: "16px",
              borderColor: "#e0e0e07a",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeader": {
                position: "unset",
              },
              "& .MuiDataGrid-cell a": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f6fa",
                borderTop: "2px solid #e0e0e07a",
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600 !important",
              },
              "& .MuiDataGrid-cell:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-cell": {
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaders": {
                border: "0",
              },
              "& .MuiDataGrid-columnHeadersInner": {
                borderBottom: "1px solid #e0e0e07a !important",
              },
              "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
                {
                  display: "none",
                },
              "& .MuiDataGrid-row .MuiDataGrid-cell:empty": {
                border: 0,
                background: "white",
              },
            }}
            rows={steelListData?.rows?.length > 0 ? steelListData?.rows : []}
            columns={columns}
            density={"standard"}
            disableColumnMenu={true}
            components={{
              LoadingOverlay: SkeletonLoadingOverlay,
              Toolbar: SteelFilters,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Records Available
                </Stack>
              ),
            }}
            disableSelectionOnClick={true}
            rowCount={steelListData?.total}
            rowsPerPageOptions={[]}
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            componentsProps={{
              toolbar: {
                steelData: steelData,
                setSteelData: setSteelData,
              },
            }}
          />
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverOutlinedIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteSteel({ steelId: deletId, callback: refreshSteel })
                  ).then(() => {
                    handleClose();
                  });
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddSteel
        open={addOpen}
        handleClose={handleAddClose}
        editData={editSteelData}
        title={editSteelData?.id ? "Edit Steel" : "Add Steel"}
        refreshSteel={refreshSteel}
      />
    </Box>
  );
};
