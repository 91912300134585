import moment from "moment";

export function getProcessingDateByWorkDays(
  // @ts-ignore
  date,
  workDays: number
) {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = workDays;
  date = moment.utc(date);
  const newDate = date.clone();
  while (daysRemaining > 0) {
    newDate.subtract(1, "days");
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }
  return newDate;
}
