import { PopUpMain } from "../../component/popUp";
import { FixedSizeList as List } from "react-window";
import Grid from "@mui/material/Grid";
import * as React from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, FieldProps } from "formik";
import Divider from "@mui/material/Divider";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { addInventory } from "../../api/inventory-api/Inventory.service";
import * as yup from "yup";

export function AddInventory(props: any) {
  const { title = "Add Steel" } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"lg"}>
        <Box>
          <AddInventoryScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
}

export interface StateStruct {
  state: string;
  abbrevation: string;
}

interface InventoryState {
  date: any;
  steelUsed: any;
}

export const AddInventoryScreen = (props: any): JSX.Element => {
  const { handleClose, handleCancel } = props;

  const { inventoryAllData, inventoryAddPageLoading } = useSelector(
    (state: RootState) => state.inventoryState
  );

  const [initValue, setInitValue] = React.useState<InventoryState>({
    date: "",
    steelUsed: [
      {
        quantity: 0,
      },
    ],
  });

  const dispatch: AppDispatch = useDispatch();

  const formatData = (inventoryAllData: any[]) => {
    if (!Array.isArray(inventoryAllData)) {
      return [];
    }

    return inventoryAllData?.map((row) => ({
      id: row._id,
      productName: row.productName,
      code: row.code,
      manufacturer: row.manufacturer,
    }));
    // .sort((a, b) => {
    //   const nameA = a.code.toUpperCase(); // ignore upper and lowercase
    //   const nameB = b.code.toUpperCase(); // ignore upper and lowercase
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }
    //   return 0;
    // });
  };

  React.useEffect(() => {
    setInitValue({
      date: dayjs(Date.now()),
      steelUsed: formatData(inventoryAllData),
    });
  }, [inventoryAllData]);

  // @ts-ignore
  const Row = ({ data: { values, setFieldValue }, index, style }) => {
    const steel = values.steelUsed[index];

    const style1 = {
      ...style,
      display: "flex",
      paddingTop: "30px",
    };

    return (
      <div key={index} style={style1} data-sc={2}>
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {steel.code}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">{steel.productName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {steel.manufacturer}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Field name={`steelUsed[${index}].quantity`}>
            {({ field, meta }: FieldProps) => (
              <FormControl>
                <TextField
                  label="Quantity"
                  name={`steelUsed[${index}].quantity`}
                  id={`steelUsed[${index}].quantity`}
                  type="number"
                  sx={{ width: 140 }}
                  required={
                    !values?.steelUsed?.some(
                      (steel: any) => steel?.quantity !== ""
                    )
                  }
                  size="small"
                  value={field.value ?? ""}
                  onChange={(event: any) => {
                    handleQuantityChange(
                      event,
                      setFieldValue,
                      `steelUsed[${index}].quantity`,
                      index
                    );
                  }}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>
      </div>
    );
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: any,
    Name: any,
    index: any
  ) => {
    const newQuantity = event.target.value;
    fieldName(Name, newQuantity);
  };

  return (
    <React.Fragment>
      <>
        {inventoryAddPageLoading ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    gap: "30px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="h5" align="left">
                    Date
                  </Typography>
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "5vh", width: "50%" }}
                    animation={"wave"}
                  />
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ marginBottom: "20px" }}
                >
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    sx={{
                      paddingLeft: "0px !important",
                    }}
                  >
                    <Typography variant="h5" align="left">
                      Code
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={4}
                    sm={4}
                    sx={{
                      paddingLeft: "0px !important",
                    }}
                  >
                    <Typography variant="h5" align="left">
                      Product Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sm={3}
                    sx={{
                      paddingLeft: "0px !important",
                    }}
                  >
                    <Typography variant="h5" align="left">
                      Manufacturer
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={2}
                    sm={2}
                    sx={{
                      paddingLeft: "0px !important",
                    }}
                  >
                    <Typography variant="h5" align="left">
                      Quantity
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "5vh", width: "60%", marginLeft: "-10px" }}
                      animation={"wave"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "5vh", width: "60%", marginLeft: "-10px" }}
                      animation={"wave"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "5vh", width: "60%", marginLeft: "-10px" }}
                      animation={"wave"}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "5vh", width: "60%", marginLeft: "-10px" }}
                      animation={"wave"}
                    />
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : inventoryAllData?.length === 0 ? (
          <Typography
            gutterBottom
            align={"center"}
            variant={"subtitle1"}
            sx={{ padding: "5px" }}
          >
            No Records to display
          </Typography>
        ) : (
          <Formik
            initialValues={initValue}
            enableReinitialize={true}
            validationSchema={yup.object({
              steelUsed: yup.array().of(
                yup.object().shape({
                  quantity: yup
                    .number()
                    .min(1, "Qty must be greater than 1")
                    .max(999.99, "Qty is exceeding the limit"),
                })
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);

              const filteredArr = values.steelUsed
                .filter((item: any) => item?.quantity && item?.quantity !== "")
                .map(({ id, quantity }: { id: any; quantity: any }) => ({
                  steelId: id,
                  quantity,
                }));

              const data = {
                date: dayjs(values?.date).format("DD-MM-YYYY"),
                steelUsed: filteredArr,
              };

              dispatch(addInventory(data)).then(() => {
                handleCancel();
              });
            }}
          >
            {({ values, resetForm, isSubmitting, setFieldValue }) => (
              <>
                <Box
                  ml={0}
                  sx={{
                    p: 3,
                    backgroundColor: "#fff ",
                  }}
                >
                  <Form>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        gap: "30px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        align="left"
                        sx={{ margin: "10px" }}
                      >
                        Date
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Field name="date">
                          {({ field, meta }: FieldProps) => (
                            <div>
                              <DatePicker
                                label="Selected Date"
                                disabled
                                value={field.value}
                                slotProps={{
                                  actionBar: {
                                    actions: ["clear"],
                                  },
                                  textField: {
                                    size: "small",
                                  },
                                }}
                                format="DD-MM-YYYY"
                              />
                              {meta.touched && meta.error && (
                                <div
                                  style={{
                                    color: "red",
                                    marginTop: "2px",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                  }}
                                >
                                  *{meta.error}
                                </div>
                              )}
                            </div>
                          )}
                        </Field>
                      </LocalizationProvider>
                    </Grid>
                    <Divider />
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      sx={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      <Grid
                        item
                        xs={3}
                        md={3}
                        sm={3}
                        sx={{
                          paddingLeft: "0px !important",
                        }}
                      >
                        <Typography variant="h5" align="left">
                          Code
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        md={4}
                        sm={4}
                        sx={{
                          paddingLeft: "0px !important",
                        }}
                      >
                        <Typography variant="h5" align="left">
                          Product Name
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        sm={3}
                        sx={{
                          paddingLeft: "0px !important",
                        }}
                      >
                        <Typography variant="h5" align="left">
                          Manufacturer
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        sm={2}
                        sx={{
                          paddingLeft: "0px !important",
                        }}
                      >
                        <Typography variant="h5" align="left">
                          Quantity
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <List
                        height={450}
                        overscanCount={20}
                        width={"100%"}
                        itemCount={values?.steelUsed?.length}
                        itemSize={65}
                        itemData={{
                          values: values,
                          setFieldValue: setFieldValue,
                        }}
                      >
                        {Row}
                      </List>
                    </Grid>
                    <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        type="reset"
                        color="error"
                        onClick={(e) => resetForm()}
                      >
                        Reset
                      </Button>
                    </Box>
                  </Form>
                </Box>
              </>
            )}
          </Formik>
        )}
      </>
    </React.Fragment>
  );
};
