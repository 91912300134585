export const LOGIN = "LOGIN";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const GET_STATES = "GET_STATES";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const FORGOT_PWD = "FORGOT_PWD";
export const RESET_PWD = "RESET_PWD";
export const CREATE_PWD = "CREATE_PWD";
export const VERIFY_PWD_LINK = "VERIFY_PWD_LINK";
export const GET_ALL_STEEL = "GET_ALL_STEEL";
export const ADD_STEEL = "ADD_STEEL";
export const DELETE_STEEL = "DELETE_STEEL";
export const GET_DROPDOWN_STEEL = "GET_DROPDOWN_STEEL";
export const GET_EDIT_STEEL = "GET_EDIT_STEEL";
export const UPDATE_STEEL = "UPDATE_STEEL";
export const GET_TRAY_TYPES = "GET_TRAY_TYPES";
export const ADD_TRAY_TYPES = "ADD_TRAY_TYPES";
export const EDIT_TRAY_TYPES = "EDIT_TRAY_TYPES";
export const GET_EDIT_TRAY_DATA = "GET_EDIT_TRAY_DATA";
export const DELETE_TRAY_TYPE = "DELETE_TRAY_TYPE";
export const GET_STEEL_PER_TRAY = "GET_STEEL_PER_TRAY";
export const ADD_STEEL_PER_TRAY = "ADD_STEEL_PER_TRAY";
export const DELETE_STEEL_PER_TRAY = "DELETE_STEEL_PER_TRAY";
export const EDIT_STEEL_PER_TRAY = "EDIT_STEEL_PER_TRAY";
export const GET_EDIT_STEEL_PER_TRAY = "GET_EDIT_STEEL_PER_TRAY";
export const GET_DROPDOWN_STEEL_PER_TRAY = "GET_DROPDOWN_STEEL_PER_TRAY";
export const GET_BRAND_STEEL_PER_TRAY = "GET_BRAND_STEEL_PER_TRAY";
export const GET_ALL_INVENTORY = "GET_ALL_INVENTORY";
export const GET_ALL_LIST_INVENTORY = "GET_ALL_LIST_INVENTORY";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const GET_EDIT_INVENTORY = "GET_EDIT_INVENTORY";
export const GET_DROPDOWN_INVENTORY = "GET_DROPDOWN_INVENTORY";
export const GET_STEELSHORTAGE_REPORTS = "GET_STEELSHORTAGE_REPORTS";
export const GET_DROPDOWN_STEEL_SHORTAGE_REPORT =
  "GET_DROPDOWN_STEEL_SHORTAGE_REPORT";
export const GET_DROPDOWN_MASTERSCHEDULE_REPORTS =
  "GET_DROPDOWN_MASTERSCHEDULE_REPORTS";
export const ADD_SHORTAGE = "ADD_SHORTAGE";
export const GET_ALL_SHORTAGE = "GET_ALL_SHORTAGE";
export const GET_DROPDOWN_SHORTAGE = "GET_DROPDOWN_SHORTAGE";
export const GET_REPLACEMENT_DATA = "GET_REPLACEMENT_DATA";
export const GET_ALL_CHART_SHORTAGE = "GET_ALL_CHART_SHORTAGE";
export const GET_EDIT_TEMP_ORDER = "GET_EDIT_TEMP_ORDER";
export const DELETE_TEMP_ORDER = "DELETE_TEMP_ORDER";
export const ADD_TOTAL = "ADD_TOTAL";
export const GET_ALL_TOTAL = "GET_ALL_TOTAL";
export const GET_DROPDOWN_TOTAL = "GET_DROPDOWN_TOTAL";
export const GET_ALL_CHART_TOTAL = "GET_ALL_CHART_TOTAL";
export const GET_MASTERSCHEDULE_REPORTS = "GET_MASTERSCHEDULE_REPORTS";
export const GET_ALL_USER = "GET_ALL_USER";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const RESEND_INVITE = "RESEND_INVITE";
export const GET_DROPDOWN_USER = "GET_DROPDOWN_USER";
export const GET_EDIT_USER = "GET_EDIT_USER";
export const UPDATE_USER = "UPDATE_USER";
