import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";

export const InputSearch = ({
  timeDelay = 1000,
  onSearchFn,
  componentName = "",
  ...props
}: any): JSX.Element => {
  const [value, setValue] = useState("");
  const ref = useRef<any>();

  const onChange = () => {
    onSearchFn(value);
  };

  const clearSearch = () => {
    setValue("");
    onSearchFn("");
  };

  useEffect(() => {
    ref.current = onChange;
  }, [onChange]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 1000);
  }, []);

  return (
    <div className="search">
      <TextField
        variant="outlined"
        placeholder={`Search by ${componentName}`}
        size="small"
        onChange={(e) => {
          debouncedCallback();
          setValue(e.target.value);
        }}
        value={value}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
          sx: {
            height: "35px",
            width: "370px",
            fontSize: "14px",
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: "14px",
          },
        }}
      />
    </div>
  );
};
