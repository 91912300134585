import { createSlice } from "@reduxjs/toolkit";
import {
  getSteelShortageReportsListing,
  getSteelShortageReportsFilterData,
} from "../../../api/reports/steelShortageReports/steelShortageReports.service";
interface SteelShortageReportsState {
  steelShortageReports: any;
  rowCount: number;
  filterData: any;
}

const initialState: SteelShortageReportsState = {
  steelShortageReports: {},
  rowCount: 0,
  filterData: {},
};

const SteelShortageReportsSlice = createSlice({
  name: "SteelShortageReportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSteelShortageReportsListing.fulfilled,
      (state, { payload }) => {
        state.steelShortageReports = payload?.shortageArray;
        state.rowCount = payload?.totalCount;
      }
    );
    builder.addCase(
      getSteelShortageReportsFilterData.fulfilled,
      (state, { payload }) => {
        state.filterData = payload;
      }
    );
  },
});

export const SteelShortageReducer = SteelShortageReportsSlice.reducer;
