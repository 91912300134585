import { PopUpMain } from "../../component/popUp";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as yup from "yup";
import { intialSteelData } from "./data";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { addSeel, getEditSeel, updateSeel } from "../../api/steel/Api.service";

export function AddSteel(props: any) {
  const { title = "Add Steel", open } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"sm"} data-dc={1}>
        <Box>
          <AddSteelScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
}

export interface StateStruct {
  state: string;
  abbrevation: string;
}

export const AddSteelScreen = (props: any): JSX.Element => {
  const { handleClose, editData, refreshSteel } = props;
  const { steelEditData } = useSelector((state: any) => state.steelState);
  const dispatch: AppDispatch = useDispatch();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Product Name Is Required")
      .test("len", "Must be less than 50 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 50);
      }),
    code: yup
      .string()
      .required("Code Is Required")
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    grade: yup
      .string()
      .required("Grade Is Required")
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    brand: yup
      .string()
      .required("Brand Is Required")
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    manufacturer: yup
      .string()
      .required("Manufacturer Is Required")
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    thickness: yup
      .number()
      .required("Thickness Is Required")
      .min(1, "Thickness must be greater than 1")
      .max(99999.99, "Thickness is exceeding the limit"),
    width: yup
      .number()
      .required("Width Is Required")
      .min(1, "Width must be greater than 1")
      .max(99999.99, "Width is exceeding the limit"),
    length: yup
      .number()
      .required("Length Is Required")
      .min(1, "Length must be greater than 1")
      .max(99999.99, "Length is exceeding the limit"),
    minQty: yup
      .number()
      .required("Minimum Quantity Is Required")
      .min(0, "Minimum Quantity must be greater than 0")
      .max(999.99, "Length is exceeding the limit"),
  });

  const handleCallBack = () => {
    refreshSteel();
    handleClose();
  };

  const updateProductName = (setFieldValue: any, values: any) => {
    if (values.grade && values.thickness && values.width && values.length)
      setFieldValue(
        "name",
        `${values.grade} ${values.thickness}mm, ${values.length}mm, ${values.width}mm`
      );
    else setFieldValue("name", "");
  };

  return (
    <React.Fragment>
      <>
        {false ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                {[...Array(11)].map(() => (
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={2}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                  </Grid>
                ))}
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <Formik
            initialValues={
              editData?.id
                ? steelEditData
                  ? steelEditData
                  : intialSteelData
                : intialSteelData
            }
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              const data = {
                data: values,
                callback: handleCallBack,
              };
              if (editData?.id) {
                const updateData = {
                  data: { ...values, steelId: editData?.id },
                  callback: handleCallBack,
                };
                dispatch(updateSeel(updateData));
              } else {
                dispatch(addSeel(data));
              }
            }}
          >
            {({
              values,
              submitForm,
              resetForm,
              isSubmitting,
              touched,
              errors,
              setFieldValue,
            }) => (
              <>
                <Box
                  ml={0}
                  sx={{
                    p: 3,
                    backgroundColor: "#fff ",
                  }}
                >
                  <Form>
                    <Grid container spacing={2.5}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="code"
                          name="code"
                          size="small"
                          label="Code"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="grade"
                          name="grade"
                          size="small"
                          label="Grade"
                          onBlur={() =>
                            updateProductName(setFieldValue, values)
                          }
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="thickness"
                          name="thickness"
                          type="number"
                          size="small"
                          label="Thickness (mm)"
                          onBlur={() =>
                            updateProductName(setFieldValue, values)
                          }
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="length"
                          name="length"
                          size="small"
                          type="number"
                          label="Length (mm)"
                          onBlur={() =>
                            updateProductName(setFieldValue, values)
                          }
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="width"
                          name="width"
                          type="number"
                          size="small"
                          label="Width (mm)"
                          onBlur={() =>
                            updateProductName(setFieldValue, values)
                          }
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="name"
                          name="name"
                          size="small"
                          label="Product Name"
                          disabled
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="brand"
                          name="brand"
                          size="small"
                          label="Brand"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="manufacturer"
                          name="manufacturer"
                          size="small"
                          label="Manufacturer"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="minQty"
                          name="minQty"
                          type="number"
                          size="small"
                          label="Minimum Quantity"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {"Save"}
                      </Button>
                      <Button onClick={handleClose} variant="outlined">
                        Cancel
                      </Button>
                      {editData?.id ? (
                        <></>
                      ) : (
                        <Button
                          variant="outlined"
                          color="error"
                          type="reset"
                          onClick={(e) => resetForm()}
                        >
                          Reset
                        </Button>
                      )}
                    </Box>
                  </Form>
                </Box>
              </>
            )}
          </Formik>
        )}
      </>
    </React.Fragment>
  );
};
