import { labels } from "../pages/shortage/ShortageChart";

export const getMonthsSchedule = (params: any) => {
  let [processingStartDate, processingEndDate] = ["", ""];
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();

  if (labels.indexOf(params) < currentMonth - 1) {
    currentYear += 1;
  }

  switch (params) {
    case "Jan":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-01-01`,
        `${currentYear}-01-31`,
      ];
      break;
    case "Feb":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-02-01`,
        `${currentYear}-02-${leapYear(currentYear) ? "29" : "28"}`,
      ];
      break;
    case "Mar":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-03-01`,
        `${currentYear}-03-31`,
      ];
      break;
    case "Apr":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-04-01`,
        `${currentYear}-04-30`,
      ];
      break;
    case "May":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-05-01`,
        `${currentYear}-05-31`,
      ];
      break;
    case "Jun":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-06-01`,
        `${currentYear}-06-30`,
      ];
      break;
    case "Jul":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-07-01`,
        `${currentYear}-07-31`,
      ];
      break;
    case "Aug":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-08-01`,
        `${currentYear}-08-31`,
      ];
      break;
    case "Sep":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-09-01`,
        `${currentYear}-09-30`,
      ];
      break;
    case "Oct":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-10-01`,
        `${currentYear}-10-31`,
      ];
      break;
    case "Nov":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-11-01`,
        `${currentYear}-11-30`,
      ];
      break;
    case "Dec":
      [processingStartDate, processingEndDate] = [
        `${currentYear}-12-01`,
        `${currentYear}-12-31`,
      ];
      break;
  }
  return [processingStartDate, processingEndDate];
};

function leapYear(year: any) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
