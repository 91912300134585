export const intialTrayData = {
  id: "",
  name: "",
  type: "",
  steelGrade: "",
  thickness: 2,
  width: 20,
  length: 0,
  code: "A-158",
};
