import { createSlice } from "@reduxjs/toolkit";
import {
  getScheduleList,
  getSummaryList,
  getEditSchedule,
  getFilterData,
} from "../../../api/schedule/Api.service";

interface ScheduleState {
  scheduleListData: any;
  scheduleSummaryData: any;
  scheduleEditData: any;
  filterData: any;
}

const initialState: ScheduleState = {
  scheduleListData: {},
  scheduleSummaryData: {},
  scheduleEditData: {},
  filterData: {},
};
const ScheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    clearEditData: (state) => {
      state.scheduleEditData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScheduleList.fulfilled, (state, { payload }) => {
      state.scheduleListData = payload;
    });
    builder.addCase(getSummaryList.fulfilled, (state, { payload }) => {
      state.scheduleSummaryData = payload;
    });
    builder.addCase(getEditSchedule.fulfilled, (state, { payload }) => {
      state.scheduleEditData = payload;
    });
    builder.addCase(getFilterData.fulfilled, (state, { payload }) => {
      state.filterData = payload;
    });
  },
});

export const ScheduleSliceReducer = ScheduleSlice.reducer;
export const { clearEditData } = ScheduleSlice.actions;
