export const initialSteelOnOrderData = {
  id: "",
  purchaseOrderNo: "",
  supplier: "",
  steelUsed: [
    {
      steelId: "",
      code: "",
      brand: "",
      quantity: "",
      status: "",
      purchaseDate: "",
      deliveryDate: "",
      outstandingQty: null,
      forecastedDate: null,
    },
  ],
};

export const intialSteelData = {
  id: "",
  purchaseOrderNo: "",
  supplier: "",
  steelName: "",
  code: "",
  brand: "",
  quantity: "",
  status: "",
  purchaseDate: "",
  deliveryDate: "",
};
