import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableSearch } from "../../component/search/SearchComponent";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { AddInventory } from "./AddInventory";
import { forwardMonthsColumns } from "./forwardMonthsColumn";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import {
  getInventoryListing,
  getInventoryFilterData,
  getInventoryAllListing,
} from "../../api/inventory-api/Inventory.service";
import { checkPermission } from "../../utils/checkPermission";
import dayjs from "dayjs";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "20px",
};

export interface InventoryCollection {
  date: Date;
  quantity: number | string;
  steelId: string;
}

export interface AddInventoryPayload {
  data: InventoryCollection;
}

export const InventoryScreen = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);

  const handleClickOpen = () => {
    const currentDate = dayjs(Date.now()).format("DD-MM-YYYY");
    dispatch(
      getInventoryAllListing({
        date: currentDate,
      })
    );
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    refreshIdentity();
  };

  const handleCancel = () => {
    setAddOpen(false);
  };

  const dispatch: AppDispatch = useDispatch();

  const { userInfo, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { inventoryData, rowCount } = useSelector(
    (state: RootState) => state.inventoryState
  );

  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [inventoryFilterData, setInventoryFilterData] = useState({
    startDate: "",
    endDate: "",
    name: "",
    code: [],
    grade: "",
    brand: "",
    range: "",
    discrepancy: "",
    location: masterLocation.locationId,
  });

  const [permission, setPermission] = useState(() => {
    const getPermission = checkPermission(
      masterLocation?.locationId,
      userInfo?.ownLocation?.locationId as string,
      userInfo?.jobRole?.key as string,
      "Inventory"
    );
    return getPermission;
  });

  const [columns, setColumns] = useState<MRT_ColumnDef[]>([]);

  useEffect(() => {
    setInventoryFilterData({
      ...inventoryFilterData,
      location: masterLocation.locationId,
    });
    setPermission(() => {
      const getPermission = checkPermission(
        masterLocation?.locationId,
        userInfo?.ownLocation?.locationId as string,
        userInfo?.jobRole?.key as string,
        "Inventory"
      );
      return getPermission;
    });
  }, [masterLocation]);

  useEffect(
    () =>
      setColumns(
        forwardMonthsColumns(
          new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1,
          inventoryFilterData.startDate,
          inventoryFilterData.endDate
        )
      ),
    [inventoryFilterData.startDate, inventoryFilterData.endDate]
  );

  useEffect(() => {
    refreshIdentity();
  }, [pagination.pageIndex, pagination.pageSize, inventoryFilterData]);

  useEffect(() => {
    dispatch(getInventoryFilterData());
  }, []);

  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };

  const refreshIdentity = () => {
    const {
      brand,
      code,
      discrepancy,
      endDate,
      name,
      startDate,
      range,
      location,
      grade,
    } = inventoryFilterData;

    const searchData = {
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      brand,
      code,
      name,
      range,
      discrepancy,
      endDate,
      startDate,
      location,
      grade,
    };
    if (Boolean(startDate) !== Boolean(endDate)) return;
    dispatch(getInventoryListing(searchData));
  };

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Inventory"
            addBtnTitle="Add Inventory"
            disabled={!permission}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            // width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          <Box>
            <div
              style={{
                height: isMobileView ? "100%" : "80vh",
                // width: "100%",
                backgroundColor: "white",
                borderRadius: "6px",
                padding: "16px",
                boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
              }}
            >
              <TableSearch
                componentName="INVENTORY"
                inventoryFilterData={inventoryFilterData}
                setInventoryFilterData={setInventoryFilterData}
                setPermission={setPermission}
              />
              <MaterialReactTable
                data={inventoryData}
                columns={columns}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableTopToolbar={false}
                enableGlobalFilter={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableToolbarInternalActions={false}
                // enableStickyHeader
                enablePinning
                initialState={{
                  columnPinning: {
                    left: ["code", "name"],
                  },
                }}
                manualPagination
                onPaginationChange={setPagination}
                rowCount={rowCount}
                state={{ pagination }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [],
                }}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e0e0e07a",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    backgroundColor: "rgb(245, 246, 250)",
                    fontWeight: "600",
                    borderRight: "1px solid #e0e0e07a",
                    boxShadow: 0,
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    borderRight: "1px solid #e0e0e07a",
                    boxShadow: 0,
                  },
                }}
              />
            </div>
          </Box>
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverOutlinedIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddInventory
        open={addOpen}
        handleCancel={handleAddClose}
        title={"Add Inventory"}
        handleClose={handleCancel}
      />
    </Box>
  );
};
