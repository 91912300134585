import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { Box } from "@mui/system";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { InputSearch } from "../../component/search/InputSearch";
import { QuickSearchToolbarProps } from "../../component/search/types";
import { RootState } from "../../store/store";

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { componentName = "", searchText = "", setSearchText } = props;

  return (
    <Box>
      <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
        {componentName === "Asset" && (
          <>
            <div>
              <InputSearch
                onSearchFn={props.onSearchFn}
                componentName={componentName}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const TrayTypesFilters = (props: QuickSearchToolbarProps) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
