import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { SteelPerTrayRow } from "./SteelPerTrayRow";
import TableRowNoResults from "./TableNoResults";
import { visuallyHidden } from "@mui/utils";
import React from "react";

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const tableCells: any = [
  {
    id: "collapse",
    label: "",
  },
  {
    id: "trayName",
    label: "ASSET NAME",
  },
  {
    id: "code",
    label: "CODE",
  },
  {
    id: "action",
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props: any) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {tableCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: "600",
              backgroundColor: "#f5f6fa",
              borderRight: "1px solid #e0e0e07a",
              textAlign: "center",
            }}
          >
            {headCell.label && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                disabled={headCell.id === "action"}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const SteelPerTrayTable = (props: any): JSX.Element => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("trayName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(props?.steelPerTrayListData, getComparator(order, orderBy)),
    [order, orderBy, props?.steelPerTrayListData]
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "4px",
        border: "1px solid rgba(224, 224, 224, 1)",
        boxShadow: "unset",
        borderColor: "#e0e0e07a",
        borderBottom: "0",
      }}
    >
      <Table aria-label="steel per tray data table">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={props?.steelPerTrayListData?.length}
        />
        <TableBody>
          {visibleRows?.length ? (
            visibleRows?.map((row: any, index: number) => (
              <SteelPerTrayRow
                refreshSteelPerTray={props.refreshSteelPerTray}
                key={`row-${index}`}
                row={row}
                index={index}
                traysListData={props.traysListData}
                onEdit={props.onEdit}
                permission={props.permission}
              />
            ))
          ) : (
            <TableRowNoResults colSpan={tableCells?.length} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
