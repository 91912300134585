import { Box, Container } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import MainScreenHeader from "../../component/pageHeader";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
import { months } from "./data";
import { ShortageReportFilter } from "./Filters";
import {
  getSteelShortageReportsFilterData,
  getSteelShortageReportsListing,
} from "../../api/reports/steelShortageReports/steelShortageReports.service";
import { finalUrl } from "../../api/Api.utils";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { reformatDates } from "../../utils/reformatDates";

export const SteelShortageScreen = (): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);

  const handleClickOpen = () => {
    setAddOpen(true);
  };

  const dispatch: AppDispatch = useDispatch();
  const { isMobileView, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const { steelShortageReports, rowCount } = useSelector(
    (state: RootState) => state.steelShortageReportsState
  );

  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });

  const [shortageData, setShortageData] = useState({
    name: "",
    code: [],
    shortageRange: "",
    probability: "75 - 100",
    startDate: "",
    endDate: "",
    location: masterLocation.locationId,
  });

  useEffect(() => {
    setShortageData({
      ...shortageData,
      location: masterLocation.locationId,
    });
  }, [masterLocation]);

  const [currentMonth, setCurrentMonth] = useState("jan");

  const refreshValues = () => {
    const {
      name,
      code,
      shortageRange,
      startDate,
      endDate,
      probability,
      location,
    } = shortageData;

    const searchDate = {
      page: pageState.page,
      pageSize: pageState.pageSize,
      startDate: startDate,
      endDate: endDate,
      location: location,
      name,
      code,
      probability,
      shortageRange,
    };

    if (Boolean(startDate) !== Boolean(endDate)) return;
    dispatch(getSteelShortageReportsListing(searchDate));
    dispatch(getSteelShortageReportsFilterData());
  };

  //This useEffect for setting up inital month
  useEffect(() => {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth();
    const shortMonth = months[monthIndex];
    setCurrentMonth(shortMonth);
  }, []);

  useEffect(() => {
    refreshValues();
  }, [pageState.page, pageState.pageSize, shortageData]);

  const onDownload = () => {
    let {
      name,
      code,
      shortageRange,
      startDate,
      endDate,
      probability,
      location,
    } = shortageData;

    startDate = reformatDates(startDate);
    endDate = reformatDates(endDate);

    const codeUrl = generateCodeUrl(code);
    const downloadUrl =
      finalUrl +
      `steelShortage/csvReport?${name ? `name=${name}&` : ""}${codeUrl}${
        startDate ? `startDate=${startDate}&` : ""
      }${endDate ? `endDate=${endDate}&` : ""}${
        shortageRange ? `shortageRange=${shortageRange}&` : ""
      }${
        probability ? `probability=${probability}&` : ""
      }locationId=${location}`;

    window.location.href = downloadUrl;
  };

  const columnDefiner: (currentMonth: string) => GridColDef[] = () => {
    const finalColums = [
      {
        field: "code",
        headerName: "CODE",
        width: 175,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "name",
        headerName: "PRODUCT NAME",
        width: 300,
        align: "center",
        headerAlign: "center",
      },
    ];
    const index =
      months.indexOf(currentMonth.toLowerCase()) === 0
        ? 11
        : months.indexOf(currentMonth.toLowerCase()) - 1;

    for (let i = 0; i < 12; i++) {
      const nextIndex = (index + i) % 12;
      const monthHeader = months[nextIndex];
      const newColums = {
        // field: monthHeader,
        field: monthHeader?.charAt(0).toUpperCase() + monthHeader?.slice(1),
        headerName: monthHeader?.toUpperCase(),
        width: 90,
        sortable: false,
        headerAlign: "center",
        align: "center",
      };
      finalColums.push(newColums);
    }

    return finalColums as GridColDef[];
  };

  const columns: GridColDef[] = columnDefiner(currentMonth);

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <MainScreenHeader
            onClickHandle={handleClickOpen}
            headerName="Steel Forecast Demand Report"
            isAddBtn={false}
          />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "80vh",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 1px 4px 0 rgba(21, 34, 50, 0.08)",
          }}
        >
          <DataGrid
            sx={{
              padding: "16px",
              borderColor: "#e0e0e07a",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeader": {
                position: "unset",
              },
              "& .MuiDataGrid-cell a": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f6fa",
                borderTop: "2px solid #e0e0e07a",
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600 !important",
              },
              "& .MuiDataGrid-cell:first-child": {
                borderLeft: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-cell": {
                borderRight: "1px solid #e0e0e07a",
              },
              "& .MuiDataGrid-columnHeaders": {
                border: "0",
              },
              "& .MuiDataGrid-columnHeadersInner": {
                borderBottom: "1px solid #e0e0e07a !important",
              },
              "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
                {
                  display: "none",
                },
              "& .MuiDataGrid-row .MuiDataGrid-cell:empty": {
                border: 0,
                background: "white",
              },
            }}
            localeText={{
              toolbarExport: "",
            }}
            rows={steelShortageReports?.length > 0 ? steelShortageReports : []}
            columns={columns}
            density={"standard"}
            disableColumnMenu={true}
            components={{
              LoadingOverlay: SkeletonLoadingOverlay,
              Toolbar: ShortageReportFilter,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Records Available
                </Stack>
              ),
            }}
            disableSelectionOnClick={true}
            rowCount={rowCount}
            rowsPerPageOptions={[]}
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            componentsProps={{
              toolbar: {
                componentName: "REPORTSTEELSHORTAGE",
                shortageData: shortageData,
                setShortageData: setShortageData,
                onDownload: onDownload,
              },
            }}
          />
        </div>
      </Container>
    </Box>
  );
};
