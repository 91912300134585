import { PopUpMain } from "../../component/popUp";
import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { createCapatalizeText, createCapatalizeText2 } from "../../utils";
import { getReplacementData } from "../../api/shortage/Api.service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
export function ViewShortage(props: any) {
  const { title = "Add Steel", open } = props;
  return (
    <Box>
      <PopUpMain title={title} {...props} size={"lg"}>
        <Box>
          <ViewShortageScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
}
export interface StateStruct {
  state: string;
  abbrevation: string;
}
export const ViewShortageScreen = (props: any): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { shortageData } = props;

  const { replacementData, tempOrderData } = useSelector(
    (state: RootState) => state.shortageState
  );

  useEffect(() => {
    dispatch(
      getReplacementData({
        id: shortageData?.id,
        monthName: shortageData?.fieldName,
        value: shortageData?.value,
        tempTrayPOOrderId: tempOrderData && tempOrderData?._id,
      })
    );
  }, []);

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      width: 180,
    },
    {
      field: "name",
      headerName: "Steel Name",
      width: 180,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 180,
    },
    {
      field: "thickness",
      headerName: "Thickness",
      width: 180,
    },
    {
      field: "length",
      headerName: "Length",
      width: 180,
    },
    {
      field: "width",
      headerName: "Width",
      width: 180,
    },
    {
      field: "locationName",
      headerName: "Location",
      width: 180,
      valueGetter: (params) =>
        `${createCapatalizeText2(params.row.location.name)}`,
    },
    {
      field: "sheetsAvailable",
      headerName: `Available Quantity in ${createCapatalizeText(
        shortageData?.fieldName
      )}`,
      width: 180,
    },
    {
      field: "replacementSheetQty",
      headerName: "Replacement Sheets",
      width: 180,
    },
  ];

  return (
    <React.Fragment>
      <>
        <Box py={2}>
          <Typography variant={"body1"}>
            Replacement Options for <b>{shortageData?.name}</b>, Value in{" "}
            <b>{createCapatalizeText(shortageData?.fieldName)}</b> is{" "}
            <b>{shortageData?.value}</b>
          </Typography>
        </Box>
        <Box>
          <DataGrid
            sx={{
              border: 1,
              borderColor: "#e0e0e07a",
              height: "50vh",
              overflow: "scroll",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeader": {
                position: "unset",
              },
              "& .MuiDataGrid-cell a": {
                color: "primary.main",
              },
            }}
            rows={replacementData?.length > 0 ? replacementData : []}
            columns={columns}
            density={"standard"}
            disableColumnMenu={true}
            rowsPerPageOptions={[]}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Records Available
                </Stack>
              ),
            }}
          />
        </Box>
      </>
    </React.Fragment>
  );
};
