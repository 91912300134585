import { MRT_ColumnDef } from "material-react-table";

const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export const genericForwardMontsColumn = ({
  monthIndex,
  customRender,
  customRenderFn,
  isUpperCase,
  width = 50,
  sortable = true,
}: any): any => {
  const finalColums = [];
  const currentMonth = months[monthIndex];
  const index = months?.indexOf(currentMonth?.toLowerCase());
  for (let i = 0; i < 12; i++) {
    const nextIndex = (index + i) % 12;
    const monthHeader = months[nextIndex];
    const newColums: any = {
      accessorKey: !isUpperCase
        ? monthHeader?.charAt(0) + monthHeader?.slice(1)
        : monthHeader?.charAt(0)?.toUpperCase() + monthHeader?.slice(1),
      header: monthHeader?.toUpperCase(),
      size: width,
      sortable: sortable,
      enablePinning: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    };
    if (customRender) {
      newColums.Cell = customRenderFn;
    }

    finalColums.push(newColums);
  }

  return finalColums as MRT_ColumnDef[];
};
