import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getTotalList } from "../../../api/totalR/Api.service";

interface totalRState {
  totalRListData: any;
  selectedFilters: {
    probability: string;
    location: string;
  };
}

const initialState: totalRState = {
  totalRListData: {},
  selectedFilters: {
    probability: "",
    location: "",
  },
};
const TotalRSlice = createSlice({
  name: "totalR",
  initialState,
  reducers: {
    setSelectedFilter: (
      state: any,
      action: PayloadAction<{ field: string; value: string }>
    ) => {
      const { field, value } = action.payload;
      if (state.selectedFilters.hasOwnProperty(field)) {
        state.selectedFilters[field] = value;
      }
    },
    clearSelectedFilters: (state: any) => {
      state.selectedFilters = { ...initialState.selectedFilters };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalList.fulfilled, (state, { payload }) => {
      state.totalRListData = payload;
    });
  },
});

export const TotalRSliceReducer = TotalRSlice.reducer;
export const { setSelectedFilter, clearSelectedFilters } = TotalRSlice.actions;