import { PopUpMain } from "../../component/popUp";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {
  Box,
  Button,
  MenuItem,
  Skeleton,
  Stack,
  SelectChangeEvent,
  Typography,
  Tooltip,
} from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as yup from "yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { intialSteelData } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { addSchedule, updateSchedule } from "../../api/schedule/Api.service";
import { AppDispatch, RootState } from "../../store/store";
import { getProcessingDateByWorkDays } from "../../utils/getProcessingDateByWorkDays";

export function AddScheduleOrder(props: any) {
  const { title = "Add Steel", open } = props;

  return (
    <Box>
      <PopUpMain title={title} {...props} size={"sm"}>
        <Box>
          <AddScheduleOrderScreen {...props} />
        </Box>
      </PopUpMain>
    </Box>
  );
}

// @ts-ignore
export function dateFormater(date, separator) {
  var day = date.getDate();
  // add +1 to month because getMonth() returns month from 0 to 11
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  // show date and month in two digits
  // if month is less than 10, add a 0 before it
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  // now we have day, month and year
  // use the separator to join them
  return day + separator + month + separator + year;
}
// @ts-ignore
export function dateFormatter(date, separator) {
  var day = date.getDate();
  // add +1 to month because getMonth() returns month from 0 to 11
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  // show date and month in two digits
  // if month is less than 10, add a 0 before it
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  // now we have day, month and year
  // use the separator to join them
  return day + separator + month + separator + year;
}

export interface StateStruct {
  state: string;
  abbrevation: string;
}

export const AddScheduleOrderScreen = (props: any): JSX.Element => {
  const { handleClose, editData, refreshSchedule, traysListData } = props;
  const { scheduleEditData } = useSelector((state: any) => state.scheduleState);
  const { masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );

  const dispatch: AppDispatch = useDispatch();

  const handleCallBack = () => {
    refreshSchedule();
    handleClose();
  };

  const validationSchema = yup.object({
    kdNumber: yup
      .string()
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      })
      .required("KD Number is required"),
    trayId: yup.string().required("Asset type is required"),
    customerName: yup
      .string()
      .required("Customer name is required")
      .test("len", "Must be less than 30 characters", (val) => {
        if (val === undefined) return true;
        return val.length === 0 || (val.length >= 1 && val.length <= 30);
      }),
    quantity: yup
      .number()
      .required("Quantity is required")
      .max(999.99, "Quantity is exceeding the limit"),
    probability: yup
      .number()
      .required("Probabilty is required")
      .min(0, "Probability must be greater than or equal to 0")
      .max(100, "Probability must be less than or equal to 100"),
    purchaseDate: yup.string().required("Purchase date is required"),
    deliveryDate: yup.string().required("Delivery date is required"),
    processingDate: yup.string().required("Processing date is required"),
  });

  const handleOnDateChange = (
    event: DateTime | null,
    fieldName: any,
    Name: any
  ) => {
    if (
      Name === "purchaseDate" ||
      Name === "deliveryDate" ||
      Name === "deliveryDate-FPT" ||
      Name === "processingDate"
    ) {
      if (Name === "deliveryDate" && !event?.toISODate())
        fieldName("processingDate", "");
      if (event) {
        const tempDate = event.toISODate();
        fieldName(Name, tempDate);
        if (Name === "deliveryDate-FPT") {
          fieldName("deliveryDate", tempDate);
        }
        if (Name === "deliveryDate") {
          const tempDate = event.toISODate();
          const d = getProcessingDateByWorkDays(tempDate, 30);
          fieldName("processingDate", d._d.toISOString().split("T")[0]);
        }
      } else {
        fieldName(Name, "");
      }
    }
  };

  return (
    <React.Fragment>
      <>
        {false ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                {[...Array(11)].map(() => (
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={2}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                  </Grid>
                ))}
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <Formik
            initialValues={
              editData?.trayId
                ? scheduleEditData
                  ? scheduleEditData
                  : intialSteelData
                : intialSteelData
            }
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              const data = {
                data: values,
                callback: handleCallBack,
              };
              data.data = {
                ...values,
                locationId: masterLocation?.locationId,
                deliveryDate: values?.deliveryDate
                  .split("-")
                  .reverse()
                  .join("-"),
                purchaseDate: values?.purchaseDate
                  .split("-")
                  .reverse()
                  .join("-"),
                processingDate: values?.processingDate
                  .split("-")
                  .reverse()
                  .join("-"),
              };
              if (editData?.trayId) {
                const updateData = {
                  data: {
                    ...values,
                    locationId: editData?.locationId,
                    trayId: editData?.trayId,
                    trayOrderId: editData?._id,
                    deliveryDate: values?.deliveryDate
                      .split("-")
                      .reverse()
                      .join("-"),
                    purchaseDate: values?.purchaseDate
                      .split("-")
                      .reverse()
                      .join("-"),
                    processingDate: values?.processingDate
                      .split("-")
                      .reverse()
                      .join("-"),
                  },
                  callback: handleCallBack,
                };
                dispatch(updateSchedule(updateData));
              } else {
                dispatch(addSchedule(data));
              }
            }}
          >
            {({
              values,
              submitForm,
              resetForm,
              isSubmitting,
              touched,
              errors,
              setFieldValue,
            }) => (
              <>
                <Box
                  ml={0}
                  sx={{
                    p: 3,
                    backgroundColor: "#fff ",
                  }}
                >
                  <Form>
                    <Grid container spacing={2.5}>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography sx={{ marginTop: "10px" }}>
                          KD Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Field
                          component={TextField}
                          id="kdNumber"
                          name="kdNumber"
                          size="small"
                          required
                          label="KD Number"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography sx={{ marginTop: "10px" }}>
                          Asset Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Field
                          component={TextField}
                          type="text"
                          name="trayId"
                          id="trayId"
                          label="Asset Name"
                          select
                          required
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          {traysListData?.map((option: any) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6}>
                        <Typography sx={{ marginTop: "10px" }}>
                          Customer Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Field
                          component={TextField}
                          id="customerName"
                          name="customerName"
                          size="small"
                          required
                          label="Customer Name"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} md={6}>
                        <Typography sx={{ marginTop: "10px" }}>
                          Quantity
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Field
                          component={TextField}
                          id="quantity"
                          name="quantity"
                          type="number"
                          size="small"
                          required
                          label="Quantity"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography sx={{ marginTop: "10px" }}>
                          Probability %
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Field
                          component={TextField}
                          id="probability"
                          name="probability"
                          size="small"
                          type="number"
                          required
                          label="Probability"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography sx={{ marginTop: "10px" }}>
                          Purchase Date
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            label="Purchase Date"
                            format="dd/MM/yyyy"
                            onChange={(event) =>
                              handleOnDateChange(
                                event,
                                setFieldValue,
                                `purchaseDate`
                              )
                            }
                            value={
                              values?.purchaseDate
                                ? DateTime.fromJSDate(
                                    new Date(values?.purchaseDate)
                                  )
                                : null
                            }
                            maxDate={DateTime.fromJSDate(
                              new Date(values?.deliveryDate)
                            )}
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                                id: "purchaseDate",
                                name: "purchaseDate",
                                required: true,
                              },
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            marginTop: "10px",
                          }}
                        >
                          Delivery Date
                          <Tooltip title="SLS RTS EOL Plan">
                            <InfoOutlinedIcon
                              color="info"
                              sx={{ px: "5px", cursor: "pointer" }}
                            ></InfoOutlinedIcon>
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            label="Delivery Date"
                            format="dd/MM/yyyy"
                            onChange={(event) =>
                              handleOnDateChange(
                                event,
                                setFieldValue,
                                values?.customerName.trim().toLowerCase() ===
                                  "freeport"
                                  ? "deliveryDate-FPT"
                                  : "deliveryDate"
                              )
                            }
                            // format="DD-MM-YYYY"
                            value={
                              values?.deliveryDate
                                ? DateTime.fromJSDate(
                                    new Date(values?.deliveryDate)
                                  )
                                : null
                            }
                            minDate={DateTime.fromJSDate(
                              new Date(values?.purchaseDate)
                            )}
                            // minDate={DateTime.fromJSDate(new Date())}
                            // minDate={DateTime.fromJSDate(new Date(values?.purchaseDate)}
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                                id: "deliveryDate",
                                name: "deliveryDate",
                                required: true,
                              },
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            marginTop: "10px",
                          }}
                        >
                          Processing Date
                          <Tooltip title="SLS MAT Process SOL Plan">
                            <InfoOutlinedIcon
                              color="info"
                              sx={{ px: "5px", cursor: "pointer" }}
                            ></InfoOutlinedIcon>
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            label="Processing Date"
                            format="dd/MM/yyyy"
                            onChange={(event) =>
                              handleOnDateChange(
                                event,
                                setFieldValue,
                                "processingDate"
                              )
                            }
                            value={
                              values?.processingDate
                                ? DateTime.fromJSDate(
                                    new Date(values?.processingDate)
                                  )
                                : null
                            }
                            disabled={
                              values?.customerName.trim().toLowerCase() !==
                              "freeport"
                            }
                            maxDate={DateTime.fromJSDate(
                              new Date(values?.deliveryDate)
                            )}
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                                id: "processingDate",
                                name: "processingDate",
                                disabled:
                                  values?.customerName.trim().toLowerCase() !==
                                  "freeport",
                                required: true,
                              },
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <Box className="popupBtn" display={"flex"} gap={2} pt={2}>
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {"Save"}
                      </Button>
                      <Button onClick={handleClose} variant="outlined">
                        Cancel
                      </Button>
                      {editData?.id ? (
                        <></>
                      ) : (
                        <Button
                          variant="outlined"
                          type="reset"
                          color="error"
                          onClick={(e) => resetForm()}
                        >
                          Reset
                        </Button>
                      )}
                    </Box>
                  </Form>
                </Box>
              </>
            )}
          </Formik>
        )}
      </>
    </React.Fragment>
  );
};
