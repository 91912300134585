import React from "react";
import { Grid, Button, Box, Stack, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { forgotPassword } from "../../api/Api.service";
import { useNavigate } from "react-router-dom";
var ImgWithDoc = require("../../assets/famdocwithdoc.png");
var ImgWithoutDoc = require("../../assets/austinLogo.png");

interface Values {
  emailId: string;
}

const ConfirmPwd = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  return (
    <>
      <div className="login-container">
        {!isMobileView && (
          <div className="left-login-container">
            <img src={ImgWithDoc} alt="" className="left-login-container-img" />
          </div>
        )}
        <div className="right-login-container">
          <img
            width={"200px"}
            src={ImgWithoutDoc}
            alt=""
            className="right-login-container-img"
          />
          <div className="login-form-container">
            <div className="heading-reset-pwd-container">
              <Box component="main">
                <Stack spacing={3}>
                  <Stack direction="row" justifyContent="center" spacing={4}>
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "400",
                        }}
                      >
                        Forgot your password?
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </div>
            <div style={{ padding: 30 }}>
              <Formik
                initialValues={{
                  emailId: "",
                }}
                validate={(values) => {
                  let errors: Partial<Values> = {};
                  if (!values.emailId) {
                    errors.emailId = "Please Enter Email";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      values.emailId
                    )
                  ) {
                    errors.emailId = "Invalid Email Address";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  const resetEmail = () => {
                    return (values.emailId = "");
                  };
                  dispatch(forgotPassword({ values, callback: resetEmail }));
                }}
              >
                {({
                  values,
                  submitForm,
                  resetForm,
                  isSubmitting,
                  touched,
                  errors,
                }) => (
                  <>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            name="emailId"
                            id="emailId"
                            label="Email ID"
                            variant="outlined"
                            required
                            size="small"
                          ></Field>
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                            onClick={() => {
                              navigate(`/`);
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            style={{
                              color: "brown",
                              border: "2px solid brown",
                            }}
                            sx={{ fontSize: "15px" }}
                            startIcon={
                              <KeyboardBackspaceIcon
                                sx={{
                                  marginBottom: "2px",
                                }}
                              />
                            }
                          >
                            Back to Login
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            onClick={submitForm}
                            fullWidth
                            size="small"
                            variant="contained"
                            disabled={isSubmitting}
                            sx={{ fontSize: "15px" }}
                            endIcon={
                              <SendIcon
                                sx={{
                                  marginBottom: "2px",
                                }}
                              />
                            }
                          >
                            Send Link
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPwd;
